import { NewRequestService } from './new-request.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginResponse } from '../models/responses/login.response';
import { lastValueFrom, Observable } from 'rxjs';
import { ProfileResponse } from '../models/responses/profile.response';
import { DocumentResponse } from '../models/responses/document.response';
import { CreateDocumentResponse } from '../models/responses/create-document.response';
import { DocumentModel } from '../models/document.model';
import { DocumentStatusResponse } from '../models/responses/document-status.response';
import { DocumentTypeResponse } from '../models/responses/document-type.response';
import { CommonResponse } from '../models/responses/common.response';
import { OfficeResponse } from '../models/responses/office.response';
import { OfficeTypeResponse } from '../models/responses/office-type.response';
import { CabinetResponse, CreateCabinetResponse } from '../models/responses/cabinet.response';
import { CabinetModel } from '../models/cabinet.model';
import { CreateOfficeResponse } from '../models/responses/create-office.response';
import { OfficeModel } from '../models/office.model';
import { UserModel } from '../models/user.model';
import { UsersResponse } from '../models/responses/users.response';
import { RoleResponse } from '../models/responses/role.response';
import { OrganizationResponse, OrganizationsResponse } from '../models/responses/organization.response';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { ReportsResponse } from '../models/responses/reports.response';
import { TrackingsModelResponse } from '../models/tracking.model';
import { LetterType } from '../models/letter-type.model';
import { CreateLetterTypeResponse, LetterTypeResponse } from '../models/responses/letter-type.response';
import { PositionResponse } from '../models/responses/position.response';
import { StatusResponse } from '../models/responses/status.response';
import { SummaryDocumentFileResponse } from '../models/responses/summary-document-file.response';
import { TypeResponse } from '../models/responses/type.response';
import { IExternalDoc } from '../models/external-doc';
import { IExtDocumentResponse } from '../models/responses/ext-doc.response';

export enum Endpoints {
  states = 'states',
  download_file = 'api/document/download/',
  request_temp_by_id = 'requests/temporary/',
  cabinet_by_id = 'cabinets/',
  cabinet_by_office_id = 'cabinets/offices/',
  role_by_position = 'roles/position/',
  request_by_id = 'requests/',
  update_request = 'requests/',
  tracking_request = 'activities/',
  request_user = 'users/requests/',
  request_type = 'request-types',
  badge = 'requests/summary/',
  accessibilities = 'accessibilities',
  qualities = 'qualities',
  login = 'login',
  profile = 'profile',
  docs = 'docs',
  public = 'public',
  deleteFileNotInDocument = 'docs/file/',
  officesTreeParent = 'offices/tree/parents',
  document_type = 'document-types/',
  letterSet = 'letter-sets/',
  docById = 'docs/',
  letterType = 'letter-types',
  letterTypeByCode = 'letter-types/code/',
  letterTypeTree = 'letter-types/tree',
  parentDoc = 'docs/replied',
  parentDocWithoutID = 'docs/replied',
  editDocById = 'docs/',
  searchDoc = 'docs',
  searchParentDoc = 'docs/replied',
  docStatus = 'doc_status',
  docTypes = 'doc_types',
  docTypesTree = 'doc_types/tree',
  docTypesById = 'doc_types/',
  docTypeByCode = 'doc_types/code/',
  offices = 'offices',
  officesTree = 'offices/tree',
  publicOfficesTree = 'public/offices/tree',
  officesTreeBaseOnUser = 'offices/manage/tree',
  officesBaseOnUser = 'offices/manage',
  officeById = 'offices/',
  officeByName = 'offices/name/',
  cabinets = 'cabinets',
  cabinetsTree = 'cabinets/tree',
  cabinetsById = 'cabinets/',
  roles = 'roles',
  uploadFileToDocumentById = 'docs/upload/',
  uploadFile = 'docs/upload',
  downloadFileById = 'docs/web/download/',
  deleteDocByID = 'docs/',
  deleteFileByID = 'docs/file/',
  users = 'users',
  users_activities = 'activities',
  reports = 'report/office',
  expired_reports = 'report/expired_doc',
  report_file_download = 'report/summary/download-file',
  resetPassword = 'users/reset/',
  usersById = 'users/',
  usersByName = 'users/name/',
  activateUserById = 'users/activate/',
  deactivateUserById = 'users/deactivate/',
  usersOfficeById = 'users/office/',
  organization = 'organization',
  organizationById = 'organization/',
  changePassword = 'change_pwd',
  totalReceived = 'report/total/received',
  totalSent = 'report/total/send',
  totalCreated = 'report/total/created',
  barChartType = 'report/summary/type',
  barChartOffice = 'report/summary/office',
  summaryTotal = 'report/summary/total',
  summaryTotalValid = 'report/summary/valid',
  trackingOffice = 'tracking/office',
  requests = 'requests',
  approve = 'requests/approve/',
  reject = 'requests/reject/',
  request = 'docs/request/',
  user_edit_request = 'users/requests/',
  reset_password_link = 'users/requests/reset-password/',
  notifications = 'notifications',
  position = 'positions/',
  request_statuses = 'statuses/',
  verify_request = 'requests/verify/',
  user_by_id = 'users/',
  upload_avatars = 'avatars',
  get_avatars = 'avatars/',
  upload_avatars_to_user = 'avatars/users/',
  get_folders = 'folders',
  get_folder_by_id = "folders/",
  update_folder = 'cabinets/boxes/',
  get_boxes = 'boxes',
  get_box_by_cabinet_id = 'cabinets/boxes/',
  get_report_document_state = 'dashboard/document/state',
  get_report_document_type = 'dashboard/document/document-type',
  get_doc_report = 'report/summary/letter-type',
  get_doc_office_report = 'report/summary/documents/office',
  box_by_id = 'boxes/',
  letter_set_by_id = 'letter-types/',
  get_summary_requet_status = 'dashboard/request/status',
  request_delete_doc = 'docs/request-delete/',
  request_del_sum = 'requests/summary-delete/',
  external_doc = 'external-documents',
  external_doc_by_id = 'external-documents/',
  external_doc_file = 'external-documents/files/',
}

@Injectable({
  providedIn: 'root'
})
export class NewAPIService {
  constructor(
    private newRequestService: NewRequestService,
    private http: HttpClient
  ) { }
  async  fetchFileFromUrl(data: string): Promise<File> {
    const apiUrl = environment.baseUrl + 'external-documents/file'
    const headers = this.newRequestService.getAuthHeader();
    const response = await lastValueFrom(
      this.http.post(apiUrl, { url : data }, { headers, responseType: 'blob' })
    );
    console.log('response',response)
    const fileName = data.split('/').pop();
    const file = new File([response], fileName, { type: 'application/pdf' });
    return file;
  }

  public login(user): Observable<LoginResponse> {
    const url = environment.baseUrl + Endpoints.login;
    return this.newRequestService.postJSON(url, user, null, false);
  }

  public getBarChartType(startDate: string, endDate: string) {
    const url = environment.baseUrl + Endpoints.barChartType;
    return this.newRequestService.getJSON(url, { from: startDate, to: endDate });
  }

  public getNotifications(currentPage: number): Observable<any> {
    const url = environment.baseUrl + Endpoints.notifications;
    return this.newRequestService.getJSONWithoutMessage<any>(url, { page: currentPage.toString(10) });
  }

  public getProfile(): Observable<ProfileResponse> {
    const url = environment.baseUrl + Endpoints.profile;
    return this.newRequestService.getJSON(url);
  }

  public getBarChartOffice(from: string, to: string) {
    const url = environment.baseUrl + Endpoints.barChartOffice;
    return this.newRequestService.getJSON<any>(url, { from, to });
  }

  public getSummaryTotal(startDate: string, endDate: string) {
    const url = environment.baseUrl + Endpoints.summaryTotal;
    return this.newRequestService.getJSON(url, { from: startDate, to: endDate });
  }

  public getSummaryTotalValid(data: { from: string, to: string, [key: string]: any }) {
    const url = environment.baseUrl + Endpoints.summaryTotalValid;
    return this.newRequestService.getJSON<any>(url, data);
  }
  
  public getDocumentsByParams(params: { [key: string]: any }): Observable<DocumentResponse> {
    const url = environment.baseUrl + Endpoints.docs;
    return this.newRequestService.getJSON<DocumentResponse>(url, { page: 1, count: Number.MAX_SAFE_INTEGER, ...params });
  }
  
  public getDocumentsByPage(currentPage = 1, limit = 10, searchQuery: any): Observable<DocumentResponse> {
    const url = environment.baseUrl + Endpoints.docs;

    return this.newRequestService.getJSON(url, {
      page: currentPage.toString(10),
      count: limit.toString(10),
      search: searchQuery.search,
      office: searchQuery.office,
      doc_type: searchQuery.doc_type,
      cabinet: searchQuery.cabinet,
      box: searchQuery.box,
      folders: searchQuery.folder,
      is_expired: searchQuery.is_expired,
      states: searchQuery.state != 0 ? searchQuery.state : undefined,
      accessibilities: searchQuery.accessibilities,
      start: searchQuery.start_date,
      end: searchQuery.end_date,
      date: searchQuery.date
    });
  }

  public getMinorDocuments(data: any) {
    return this.newRequestService.getJSON<DocumentResponse>(environment.baseUrl + "docs/minor", data);
  }

  public getPublicDocumentsByPage(currentPage = 1, limit = 10, searchQuery: any): Observable<DocumentResponse> {
    const url = environment.baseUrl + Endpoints.public;
    return this.newRequestService.getJSON(url, {
      page: currentPage.toString(10),
      count: limit.toString(10),
      search: searchQuery.search,
      office: searchQuery.office,
      doc_type: searchQuery.doc_type
    })
  }

  public changeOwnPassword(newPassword: string, oldPassword: string) {
    const url = environment.baseUrl + Endpoints.changePassword;
    return this.newRequestService.postJSON(url, {
      old_pwd: oldPassword,
      new_pwd: newPassword
    })
  }

  public createDocument(data: any): Observable<CreateDocumentResponse> {
    const url = environment.baseUrl + Endpoints.docs;
    return this.newRequestService.postJSON<CreateDocumentResponse>(url, data);
  }
  
  public createExternalDocument(data: any): Observable<CreateDocumentResponse> {
    const url = environment.baseUrl + 'external-documents';
    return this.newRequestService.postJSON<CreateDocumentResponse>(url, data);
  }
  public editDocument(docID: string, document: DocumentModel): Observable<CreateDocumentResponse> {
    const url = environment.baseUrl + Endpoints.editDocById + docID;
    // console.log('You Updated Document with ', document, url);
    return this.newRequestService.patchJSON<CreateDocumentResponse>(url, document);
  }

  public getDocumentStatuses(): Observable<DocumentStatusResponse> {
    const url = environment.baseUrl + Endpoints.docStatus;
    return this.newRequestService.getJSON<DocumentStatusResponse>(url);
  }

  public getDocumentById(id: string): Observable<CreateDocumentResponse> {
    const url = environment.baseUrl + Endpoints.docById + id;
    if (id != undefined) {
      return this.newRequestService.getJSON<CreateDocumentResponse>(url);
    }
  }

  public getPublicDocumentById(id: string): Observable<CreateDocumentResponse> {
    const url = environment.baseUrl + Endpoints.public;
    if (id == undefined) {
      return this.newRequestService.getJSON<CreateDocumentResponse>(url);
    } else {
      return this.newRequestService.getJSON<CreateDocumentResponse>(url, { id: id });
    }
  }

  public getParentDocument(id: string): Observable<CreateDocumentResponse> {
    const url = environment.baseUrl + Endpoints.parentDoc + id;
    return this.newRequestService.getJSON<CreateDocumentResponse>(url);
  }

  public getParentDocumentWithoutID(): Observable<CreateDocumentResponse> {
    const url = environment.baseUrl + Endpoints.parentDocWithoutID;
    return this.newRequestService.getJSON<CreateDocumentResponse>(url);
  }

  public searchParentDocument(searchText: string) {
    const url = environment.baseUrl + Endpoints.searchParentDoc + searchText;
    return this.newRequestService.getJSON<CreateDocumentResponse>(url);
  }

  public getDocumentTypes(): Observable<DocumentTypeResponse> {
    const url = environment.baseUrl + Endpoints.docTypes;
    return this.newRequestService.getJSON<DocumentTypeResponse>(url);
  }

  public getDocumentTypeById(id: string): Observable<DocumentTypeResponse> {
    const url = environment.baseUrl + Endpoints.document_type + id;
    return this.newRequestService.getJSON<DocumentTypeResponse>(url);
  }

  public getDocumentByCode(code: string): Observable<DocumentTypeResponse> {
    const url = environment.baseUrl + Endpoints.docTypeByCode + code;
    return this.newRequestService.getJSON<DocumentTypeResponse>(url);
  }

  public deleteDocumentType(documentTypeId: string): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.docTypesById + documentTypeId;
    return this.newRequestService.delete<CommonResponse>(url);
  }

  public getOffices(): Observable<OfficeResponse> {
    const url = environment.baseUrl + Endpoints.offices;
    return this.newRequestService.getJSON<OfficeResponse>(url);
  }

  public getOfficesTree(): Observable<OfficeResponse> {
    // const url = environment.baseUrl + Endpoints.officesTree;
    const url = environment.baseUrl + "offices/manage/tree";
    return this.newRequestService.getJSON<OfficeResponse>(url);
  }

  public getAllOfficesTree(): Observable<OfficeResponse> {
    const url = environment.baseUrl + "offices/manage-and-public/tree";
    return this.newRequestService.getJSON<OfficeResponse>(url);
  }

  public getPublicOfficesTree(): Observable<OfficeResponse> {
    const url = environment.baseUrl + Endpoints.publicOfficesTree;
    return this.newRequestService.getJSON<OfficeResponse>(url);
  }

  // public getDocTypesTree(): Observable<DocumentTypeResponse> {
  //   const url = environment.baseUrl + Endpoints.docTypesTree;
  //   return this.newRequestService.getJSON<DocumentTypeResponse>(url);
  // }
  // public getLetterTypesTree(): Observable<DocumentTypeResponse> {
  //   const url = environment.baseUrl + Endpoints.letterType;
  //   return this.newRequestService.getJSON(url);
  // }
  public getDocumentTypesNew(): Observable<DocumentTypeResponse> {
    const url = environment.baseUrl + Endpoints.document_type;
    return this.newRequestService.getJSON<DocumentTypeResponse>(url);
  }
  public getLetterTypeTree(): Observable<LetterTypeResponse> {
    const url = environment.baseUrl + Endpoints.letterTypeTree;
    return this.newRequestService.getJSON<LetterTypeResponse>(url);
  }
  public getLetterSet(params: { [key: string]: any } = {}): Observable<LetterTypeResponse> {
    const url = environment.baseUrl + Endpoints.letterSet;
    return this.newRequestService.getJSON<LetterTypeResponse>(url, params);
  }
  public getLetterSetByID(id: string): Observable<LetterTypeResponse> {
    const url = environment.baseUrl + Endpoints.letter_set_by_id + id;
    return this.newRequestService.getJSON<LetterTypeResponse>(url);
  }
  public createLetterType(type: LetterType): Observable<CreateLetterTypeResponse> {
    const url = environment.baseUrl + Endpoints.letterType;
    return this.newRequestService.postJSON<CreateLetterTypeResponse>(url, type);
  }
  public editLetterType(letterID: string, body: LetterType): Observable<CreateLetterTypeResponse> {
    const url = environment.baseUrl + Endpoints.letterType + '/' + letterID;
    return this.newRequestService.patchJSON<CreateLetterTypeResponse>(url, body);
  }
  public deleteLetterType(letterID: string): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.letterType + '/' + letterID;
    return this.newRequestService.delete<CommonResponse>(url, {}, true);
  }
  public getLetterTypes(params: { [key: string]: any } = {}): Observable<LetterTypeResponse> {
    const url = environment.baseUrl + Endpoints.letterType;
    return this.newRequestService.getJSON(url, params);
  }
  public getLetterTypeByCode(code: string): Observable<LetterTypeResponse> {
    const url = environment.baseUrl + Endpoints.letterTypeByCode + code;
    return this.newRequestService.getJSONWithoutMessage<LetterTypeResponse>(url, {}, false);
  }
  public getLetterTypeByID(id: string): Observable<LetterTypeResponse> {
    const url = environment.baseUrl + Endpoints.letter_set_by_id + id;
    return this.newRequestService.getJSON<LetterTypeResponse>(url);
  }
  public officesTreeBaseOnUser(): Observable<OfficeResponse> {
    const url = environment.baseUrl + Endpoints.officesTreeBaseOnUser;
    return this.newRequestService.getJSON<OfficeResponse>(url);
  }

  public getOfficesBaseOnUser(): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.officesBaseOnUser;
    return this.newRequestService.getJSON(url);
  }

  public getOfficesTreeParent(): Observable<OfficeResponse> {
    const url = environment.baseUrl + Endpoints.officesTreeParent;
    return this.newRequestService.getJSON<OfficeResponse>(url);
  }

  public getCabinets(params: { [key: string]: any } = {}): Observable<OfficeTypeResponse> {
    const url = environment.baseUrl + Endpoints.cabinets;
    return this.newRequestService.getJSON<OfficeTypeResponse>(url, params);
  }

  public getCabinetsByOfficeID(officeID: string): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.cabinet_by_office_id + officeID;
    return this.newRequestService.getJSON<CommonResponse>(url);
  }

  public getCabinetsTree(): Observable<OfficeTypeResponse> {
    const url = environment.baseUrl + Endpoints.cabinetsTree;
    return this.newRequestService.getJSON<OfficeTypeResponse>(url);
  }

  public createCabinet(cabinet: CabinetModel): Observable<CreateCabinetResponse> {
    const url = environment.baseUrl + Endpoints.cabinets;
    return this.newRequestService.postJSON<CreateCabinetResponse>(url, cabinet);
  }

  public editCabinet(cabinetId: string, cabinet: CabinetModel): Observable<CreateCabinetResponse> {
    const url = environment.baseUrl + Endpoints.cabinetsById + cabinetId;
    return this.newRequestService.patchJSON<CreateCabinetResponse>(url, cabinet);
  }

  public deleteCabinet(cabineteId: string): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.cabinetsById + cabineteId;
    return this.newRequestService.delete<CommonResponse>(url, {}, true);
  }

  public getOfficeById(officeId: string): Observable<CreateOfficeResponse> {
    const url = environment.baseUrl + Endpoints.officeById + officeId;
    return this.newRequestService.getJSON<CreateOfficeResponse>(url);
  }

  public deleteOfficeById(officeId: string): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.officeById + officeId;
    return this.newRequestService.delete<CommonResponse>(url);
  }

  public modifyOfficeById(officeId: string, office: OfficeModel): Observable<CreateOfficeResponse> {
    const url = environment.baseUrl + Endpoints.officeById + officeId;
    return this.newRequestService.patchJSON<CreateOfficeResponse>(url, office);
  }


  public findOfficeByName(officeName: string): Observable<OfficeResponse> {
    const url = environment.baseUrl + Endpoints.officeByName + officeName;
    return this.newRequestService.getJSON<OfficeResponse>(url);
  }

  public setUserStatus(userId: string, status: boolean): Observable<UserModel> {
    let requestUrl: string;
    if (status) {
      requestUrl = Endpoints.activateUserById;
    } else {
      requestUrl = Endpoints.deactivateUserById;
    }

    const url = environment.baseUrl + requestUrl + userId;

    return this.newRequestService.postJSON<UserModel>(url, {});
  }

  public createNewOffice(office: OfficeModel): Observable<CreateOfficeResponse> {
    const url = environment.baseUrl + Endpoints.offices;
    return this.newRequestService.postJSON<CreateOfficeResponse>(url, office);
  }

  public getUsers(limit: number = 10, page: number = 1): Observable<UsersResponse> {
    const url = environment.baseUrl + Endpoints.users;
    return this.newRequestService.getJSON<UsersResponse>(url, {
      limit: limit.toString(),
      page: page.toString(),
    });
  }

  public getUserActivities(params): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.users_activities;
    return this.newRequestService.getJSON(url, params);
  }

  public editUser(userId: string, body: UserModel): Observable<LoginResponse> {
    const url = environment.baseUrl + Endpoints.usersById + userId;
    return this.newRequestService.patchJSON<LoginResponse>(url, body);
  }

  public getRoles(): Observable<RoleResponse> {
    const url = environment.baseUrl + Endpoints.roles;
    return this.newRequestService.getJSON<RoleResponse>(url);
  }

  public createUser(body: UserModel): Observable<LoginResponse> {
    const url = environment.baseUrl + Endpoints.users;
    // const data = new URLSearchParams();
    // Object.keys(body).forEach(key => {
    //   if (Array.isArray(body[key])) {
    //     body[key].forEach(val => {
    //       data.append(key, val)
    //     })
    //   } else {
    //     data.append(key, body[key])
    //   }
    // });
    // console.log(data.getAll('manage_office_ids'))
    return this.newRequestService.postJSON<LoginResponse>(url, body);
  }

  public getUsersByName(name: string): Observable<UsersResponse> {
    const url = environment.baseUrl + Endpoints.usersByName + name;
    return this.newRequestService.getJSON<UsersResponse>(url);
  }

  public getReceiverByOffice(officeId: string): Observable<UsersResponse> {
    const url = environment.baseUrl + Endpoints.usersOfficeById + officeId;
    return this.newRequestService.getJSON<UsersResponse>(url);
  }

  // public resetPassword(userID: string, newPassword: string): Observable<UsersResponse> {
  //   const url = environment.baseUrl + Endpoints.resetPassword + userID;
  //   return this.newRequestService.postJSON<UsersResponse>(url, { password: newPassword });
  // }

  public resetPassword(userID: string, password): Observable<UsersResponse> {
    const url = environment.baseUrl + Endpoints.resetPassword + userID;
    return this.newRequestService.postJSON<UsersResponse>(url, { password });
  }

  public getOrganization(): Observable<OrganizationsResponse> {
    const url = environment.baseUrl + Endpoints.organization;
    return this.newRequestService.getJSON<OrganizationsResponse>(url);
  }

  public createOrganization(name: string): Observable<OrganizationResponse> {
    const url = environment.baseUrl + Endpoints.organization;
    return this.newRequestService.postJSON<OrganizationResponse>(url, { name });
  }

  public editOrganization(id: string, name: string): Observable<OrganizationResponse> {
    const url = environment.baseUrl + Endpoints.organizationById + id;
    return this.newRequestService.patchJSON<OrganizationResponse>(url, { name });
  }

  public deleteOrganization(id: string): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.organizationById + id;
    return this.newRequestService.delete<CommonResponse>(url);
  }

  public uploadFileToDocument(documentId: string, file: File, roleIds: number[]): Observable<HttpEvent<Response>> {
    // const formData = new FormData();
    // formData.append('file', file);
    // const headers = new HttpHeaders();
    // headers.append('Content-Type', 'multipart/form-data;boundary=abc');

    // const req = new HttpRequest('POST', ApiService.getUrlWithParams(Endpoints.uploadFileToDocumentById, {
    //   id: documentId
    // }), formData, {
    //   reportProgress: true,
    //   responseType: 'json',
    //   headers: headers
    // });

    // return this.newRequestService.request<Response>(req);

    const url = environment.baseUrl + Endpoints.uploadFileToDocumentById + documentId;
    // const url = "http://localhost:5000/uploads";
    const formData = new FormData();
    formData.append('file', file);
    // roleIds.forEach(roleId => {
    //   formData.append("role_ids", roleId.toString());
    // })
    // var object = {};
    // formData.forEach(function(value, key){
    //     object[key] = formData.getAll(key);
    // });
    // console.log('starting upload to ', url, " with ", object)
    return this.newRequestService.postJSON(url, formData);
  }

  /**
   * 
   * @param file 
   * @param filename 
   * @returns 
   */
  public uploadFile(file: File, filename?: string): Observable<HttpEvent<Response>> {
    const url = environment.baseUrl + Endpoints.uploadFile;
    const data = new FormData();
    data.append('file', file, filename);
    // roleIds.forEach((roleId, index) => {
    //   // [' + index +']
    //   data.append('role_ids', roleId.toString());
    // });
    return this.newRequestService.postJSONWithoutMessage(url, data, { reportProgress: true, observe: 'events' });
  }
  public uploadFileExternal(file: File, filename?: string): Observable<HttpEvent<Response>> {
    const url = environment.baseUrl + 'external-documents/upload';
    const data = new FormData();
    data.append('file', file, filename);
    // roleIds.forEach((roleId, index) => {
    //   // [' + index +']
    //   data.append('role_ids', roleId.toString());
    // });
    return this.newRequestService.postJSONWithoutMessage(url, data, { reportProgress: true, observe: 'events' });
  }
  public downloadFileById(id: string, token: string): string {
    return environment.baseUrl + Endpoints.downloadFileById + id + '?token=' + token;
    // return ApiService.getUrlWithParams(Endpoints.downloadFileById, { id, token });
  }

  public fetchFileById(id: string, is_download: boolean = false): Observable<HttpEvent<any>> {
    const url = environment.baseUrl + Endpoints.downloadFileById + id;
    return this.newRequestService.get(url, { is_download });
  }

  public deleteFile(id: string): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.deleteFileByID + id;
    return this.newRequestService.delete(url);
  }

  public deleteDocument(id: string): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.deleteDocByID + id;
    return this.newRequestService.delete(url);
  }

  public requestDeleteDocument(id: string, params: { [key: string]: any }): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.request_delete_doc + id;
    return this.newRequestService.postJSON<CommonResponse>(url, params);
  }

  public deleteFileById(doc_id: string, id: string): Observable<CommonResponse> {
    // const httpHeaders = new HttpHeaders();
    // httpHeaders.append('Content-Type', 'multipart/form-data;boundary=abc');
    // return this.newRequestService.delete<CommonResponse>(ApiService.getUrlWithParams(Endpoints.deleteFileByID, { doc_id: doc_id, id: id }),
    //   {
    //     reportProgress: true,
    //     responseType: 'json',
    //     headers: httpHeaders
    //   });

    const url = environment.baseUrl + Endpoints.deleteFileByID + doc_id + '/' + id;
    return this.newRequestService.delete(url);
  }

  public editProfile(body: { first_name: string, last_name: string }): Observable<{ message: string, status: number, profile: UserModel }> {
    const url = environment.baseUrl + Endpoints.profile;
    return this.newRequestService.patchJSON<{ message: string, status: number, profile: UserModel }>(url, body);
    // return this.newRequestService.patchJSON<{ message: string, status: number, profile: UserModel }>(ApiService.getUrl(Endpoints.profile), body);
  }

  public getReports(params): Observable<ReportsResponse> {
    const url = environment.baseUrl + Endpoints.reports;
    return this.newRequestService.getJSON<ReportsResponse>(url, params);
    // if (params.office == "null") {
    //   return this.newRequestService.getJSON<ReportsResponse>(url, {
    //     start_date: params.start_date,
    //     end_date: params.end_date
    //   })
    // } else {
    //   return this.newRequestService.getJSON<ReportsResponse>(url, {
    //     start_date: params.start_date,
    //     end_date: params.end_date,
    //     office_id: params.office_id,
    //   })
    // }
  }
  public deleteFileNotInDocument(id: string): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.deleteFileNotInDocument + id;
    return this.newRequestService.delete<CommonResponse>(url);
  }
  public getExpiredReports(params): Observable<ReportsResponse> {
    const url = environment.baseUrl + Endpoints.expired_reports;
    return this.newRequestService.getJSON<ReportsResponse>(url, params);
  }

  public getTotalReceived(startDate: string, endDate: string) {
    const url = environment.baseUrl + Endpoints.totalReceived;
    return this.newRequestService.getJSON<any>(url, { start_date: startDate, end_date: endDate });
  }

  public getTotalSent(startDate: string, endDate: string) {
    const url = environment.baseUrl + Endpoints.totalSent;
    return this.newRequestService.getJSON<any>(url, { start_date: startDate, end_date: endDate });
    // return this.newRequestService.getJSON<any>(ApiService.getUrlWithParams(Endpoints.totalSent, { start_date: startDate, end_date: endDate }));
  }

  public getTotalCreated(startDate: string, endDate: string) {
    const url = environment.baseUrl + Endpoints.totalCreated;
    return this.newRequestService.getJSON<any>(url, { start_date: startDate, end_date: endDate });

    // return this.newRequestService.getJSON<any>(ApiService.getUrlWithParams(Endpoints.totalCreated, { start_date: startDate, end_date: endDate }));
  }

  public getTrackingOffice(): Observable<TrackingsModelResponse> {
    const url = environment.baseUrl + Endpoints.trackingOffice;
    return this.newRequestService.getJSON<TrackingsModelResponse>(url);
  }

  // public getRequestsByPage(currentPage = 1, limit = 10, status: string[], onModel: 'user' | 'document', type?: string[]): Observable<any> {
  public getRequestsByPage(data): Observable<any> {
    const url = environment.baseUrl + Endpoints.requests;
    return this.newRequestService.getJSON<any>(url, data);
    
    // return this.newRequestService.getJSON<any>(url, {
    //   page: currentPage.toString(10),
    //   count: limit.toString(10),
    //   status: status,
    //   onModel,
    //   type
    // })
  }

  

  public getSummarRequestStatus(params: { [key:string] : string | number }): Observable<any> {
    const url = environment.baseUrl + Endpoints.get_summary_requet_status;
    return this.newRequestService.getJSON<any>(url, params);
  }

  public getRequestsReport(status: string[], onModel: 'user' | 'document', type?: string[]): Observable<any> {
    const url = environment.baseUrl + Endpoints.requests;
    return this.newRequestService.getJSON<any>(url, {
      status: status,
      onModel,
      type
    })
  }

  public getRequestsByDocsID(doc_id: string): Observable<any> {
    const url = environment.baseUrl + 'requests/doc/' + doc_id;
    if (doc_id != undefined) {
      return this.newRequestService.getJSON<CreateDocumentResponse>(url);
    }
  }

  public sentEditRequest(doc_id: string, params: { [key: string]: any }) {
    const url = environment.baseUrl + Endpoints.request + doc_id;
    return this.newRequestService.postJSON<any>(url, params);
    // return this.newRequestService.post<any>(ApiService.getUrlWithParams(Endpoints.request, { doc_id: doc_id }), {});
  }

  public sentEditUserRequest(user_id: string, reason: string) {
    const url = environment.baseUrl + Endpoints.user_edit_request + user_id;
    return this.newRequestService.postJSON<any>(url, { description: reason });
    // return this.newRequestService.post<any>(ApiService.getUrlWithParams(Endpoints.request, { doc_id: doc_id }), {});
  }

  public approveRequest(request_id: string, data?: any) {
    const url = environment.baseUrl + Endpoints.approve + request_id;
    return this.newRequestService.postJSON<any>(url, data);
    // return this.newRequestService.patchJSON<any>(ApiService.getUrlWithParams(Endpoints.approve, { req_id: request_id }), {});
  }

  public rejectRequest(request_id: string, reason: string) {
    const url = environment.baseUrl + Endpoints.reject + request_id;
    return this.newRequestService.postJSON<any>(url, { reason });
    // return this.newRequestService.patchJSON<any>(ApiService.getUrlWithParams(Endpoints.reject, { req_id: request_id }),
    //   {
    //     reason: reason
    //   });
  }

  public getRequestById(id: string) {
    const url = environment.baseUrl + Endpoints.requests + '/doc/' + id;
    return this.newRequestService.getJSONWithoutMessage<any>(url);
  }

  public getUserRequestById(id: string) {
    const url = environment.baseUrl + Endpoints.requests + '/users/' + id;
    return this.newRequestService.getJSONWithoutMessage<any>(url);
  }

  public uploadFileToDoc(id: string, file, roleIds: number[]): Observable<HttpEvent<Response>> {
    const url = environment.baseUrl + Endpoints.uploadFileToDocumentById + id;
    const data = new FormData();
    data.append('file', file);
    roleIds.forEach(id => {
      data.append('role_ids', id.toString());
    });
    console.log('upload to ', url);
    return this.newRequestService.postJSON(url, data);
  }
  public getPosition(): Observable<PositionResponse> {
    const url = environment.baseUrl + Endpoints.position;
    return this.newRequestService.getJSONWithoutMessage<PositionResponse>(url);
  }
  public getRequestsStatus(): Observable<StatusResponse> {
    const url = environment.baseUrl + Endpoints.request_statuses;
    return this.newRequestService.getJSONWithoutMessage<StatusResponse>(url);
  }
  public verifyRequest(reqId: string): Observable<any> {
    const url = environment.baseUrl + Endpoints.verify_request + reqId;
    return this.newRequestService.postJSON<any>(url, {});
  }
  public getQualities() {
    const url = environment.baseUrl + Endpoints.qualities;
    return this.newRequestService.getJSON<any>(url);
  }
  public getStates(): Observable<any> {
    const url = environment.baseUrl + Endpoints.states;
    return this.newRequestService.getJSON<any>(url);
  }
  public getAccessibilities(): Observable<any> {
    const url = environment.baseUrl + Endpoints.accessibilities;
    return this.newRequestService.getJSON(url);
  }
  public getBadge(model: 'user' | 'document') {
    const url = environment.baseUrl + Endpoints.badge + model;
    return this.newRequestService.getJSON(url);
  }
  public requestCreateOrUpdateUseruser(user: UserModel, userId: string = '') {
    const url = environment.baseUrl + Endpoints.request_user + userId;
    return this.newRequestService.postJSON(url, user);
  }
  public getRequestTypes(): Observable<TypeResponse> {
    const url = environment.baseUrl + Endpoints.request_type;
    return this.newRequestService.getJSON<TypeResponse>(url);
  }
  public getRequestTempById(id: string) {
    const url = environment.baseUrl + Endpoints.request_temp_by_id + id;
    return this.newRequestService.getJSON(url);
  }
  public newGetRequestById(id: string): Observable<any> {
    const url = environment.baseUrl + Endpoints.request_by_id + id;
    return this.newRequestService.getJSON<any>(url);
  }
  public updateRequestDocumentById(reqID: string, document: DocumentModel): Observable<any> {
    const url = environment.baseUrl + Endpoints.update_request + reqID;
    return this.newRequestService.putJSON<any>(url, document);
  }
  public getTrackingRequest(page: number, count: number, ref_id: string, type?: string): Observable<any> {
    const url = environment.baseUrl + Endpoints.tracking_request;
    return this.newRequestService.getJSON<any>(url, {page, count, reference: ref_id, type});
  }
  public getRoleByPositionId(id: string) {
    const url = environment.baseUrl + Endpoints.role_by_position + id;
    return this.newRequestService.getJSON(url);
  }
  public getCabinetById(id: string) {
    const url = environment.baseUrl + Endpoints.cabinet_by_id + id;
    return this.newRequestService.getJSON(url);
  }
  public getBoxByID(id: string): Observable<CabinetResponse> {
    const url = environment.baseUrl + Endpoints.box_by_id + id;
    return this.newRequestService.getJSON(url);
  }
  public searchUsers(params: { [key: string]: any }, page?: number, limit?: number): Observable<UsersResponse> {
    const url = environment.baseUrl + Endpoints.users;
    return this.newRequestService.getJSON<UsersResponse>(url, params);
  }
  public getOfficesTreeForDocumentForm() {
    const url = environment.baseUrl + Endpoints.officesTree;
    return this.newRequestService.getJSON(url);
  }
  public getUserById(id: string) {
    const url = environment.baseUrl + Endpoints.user_by_id + id;
    return this.newRequestService.getJSON(url);
  }
  // Avatars
  public uploadAvatars(avatars) {
    const url = environment.baseUrl + Endpoints.upload_avatars;
    const data = new FormData();
    data.append('avatar', avatars);
    return this.newRequestService.postJSON(url, data, {}, false);
  }
  public getAvatars(id: string) {
    const url = id ? environment.baseUrl + Endpoints.get_avatars + id : "/assets/images/nopf.png";
    return this.newRequestService.get(url, null, false, false);
  }
  public uploadAvatarsToUser(id: string, avatars) {
    const url = environment.baseUrl + Endpoints.upload_avatars_to_user + id;
    const data = new FormData();
    data.append('avatar', avatars);
    return this.newRequestService.postJSON(url, data);
  }
  public deleteAvatars(id: string) {
    const url = environment.baseUrl + Endpoints.get_avatars + id;
    return this.newRequestService.delete(url);
  }
  // FOLDER API
  public getFolders(): Observable<CabinetResponse> {
    const url = environment.baseUrl + Endpoints.get_folders;
    return this.newRequestService.getJSON<CabinetResponse>(url);
  }
  public getFolderByID(id: string): Observable<CabinetResponse> {
    const url = environment.baseUrl + Endpoints.get_folder_by_id + id;
    return this.newRequestService.getJSON<CabinetResponse>(url);
  }
  public getFoldersByBoxID(id: string): Observable<CabinetResponse> {
    const url = environment.baseUrl + Endpoints.get_folders;
    return this.newRequestService.getJSON(url, { box: id });
  }
  public getFoldersByParams(data: { [key: string]: any }): Observable<CabinetResponse> {
    const url = environment.baseUrl + Endpoints.get_folders;
    return this.newRequestService.getJSON(url, data);
  }
  public updateInCabinets(id: string, data: { [key: string]: any }): Observable<CabinetResponse> {
    const url = environment.baseUrl + Endpoints.update_folder + id;
    return this.newRequestService.patchJSON<CabinetResponse>(url, data);
  }
  //  BOXES API
  public getBoxes(params?: { [key: string]: any }): Observable<CabinetResponse> {
    const url = environment.baseUrl + Endpoints.get_boxes;
    return this.newRequestService.getJSON<CabinetResponse>(url, params)
  }
  public getBoxByCabinetID(id: string, more: { [key: string]: any } = {}): Observable<CabinetResponse> {
    const url = environment.baseUrl + Endpoints.get_boxes;
    return this.newRequestService.getJSON(url, { cabinet: id, ...more });
  }
  //  REPORTS  //
  public getReportDocumentState(start_date, end_date): Observable<any> {
    const url = environment.baseUrl + Endpoints.get_report_document_state;
    return this.newRequestService.getJSON(url, { start_date, end_date });
  }
  public getReportDocumentType(start_date, end_date): Observable<any> {
    const url = environment.baseUrl + Endpoints.get_report_document_type;
    return this.newRequestService.getJSON(url, { start_date, end_date });
  }
  public getAllDocumentReport(params: { [key: string]: any }): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.get_doc_report;
    return this.newRequestService.getJSON(url, params);
  }
  public getAllDocumentReportByGdnt(params: { [key: string]: any }): Observable<CommonResponse> {
    const url = environment.baseUrl + 'report-external-doc/summary';
    return this.newRequestService.getJSON(url, params);
  }
  public getDocumentOfficeReport(params, annaul: boolean = false): Observable<CommonResponse> {
    let yearly = annaul ? '/yearly' : '';
    const url = environment.baseUrl + Endpoints.get_doc_office_report + yearly;
    return this.newRequestService.getJSON<CommonResponse>(url, params);
  }
  public getDownloadFileReport(params?): Observable<SummaryDocumentFileResponse> {
    const url = environment.baseUrl + Endpoints.report_file_download;
    return this.newRequestService.getJSON<SummaryDocumentFileResponse>(url, params);
  }
  public sendResetPassword(username: string, token: string): Observable<CommonResponse> {
    const url = environment.baseUrl + Endpoints.reset_password_link + username;
    return this.newRequestService.postJSON(url, { recaptcha_v3_token: token }, {}, false);
  }
  // DOWNLOAD FILE
  public downloadFile(docID: string, fileID: string): Observable<HttpEvent<any>> {
    const url = environment.baseUrl + Endpoints.download_file + docID + "/" + fileID;
    return this.newRequestService.get(url);
  }
  public downloadFileWithoutAuth(id: string, params: { [key: string]: string | number | boolean }): Observable<HttpEvent<any>> {
    const url = environment.baseUrl + Endpoints.downloadFileById + id;
    return this.newRequestService.get(url, { ...params });
  }

  // EXTERNAL DOCUMENTS
  public getExternalDocuments(params: { [key: string]: any } = {}): Observable<any> {
    const url = environment.baseUrl + Endpoints.external_doc;
    return this.newRequestService.getJSON(url, params);
  }

  public getExternalDocumentById(id: string): Observable<IExtDocumentResponse> {
    const url = environment.baseUrl + Endpoints.external_doc_by_id + id;
    if (id != undefined) {
      return this.newRequestService.getJSON(url);
    }
  }

  public getExternalDocFile(file_id: string): Observable<any> {
    const url = environment.baseUrl + Endpoints.external_doc_file + file_id;
    if (file_id != undefined) {
      return this.newRequestService.get(url);
    }
  }

  public getExternalDocHistory(_id: string, data: any): Observable<any> {
    const url = environment.baseUrl + 'external-documents/' + _id + '/histories';
    if (_id != undefined) {
      return this.newRequestService.getJSONWithoutMessage(url, data);
    }
  }
  public getExternalDoc(file_id: string): Observable<any> {
    const url = environment.baseUrl + `external-documents/files/${file_id}`;
    return this.newRequestService.get(url);
    
  }

}
