<div class="container fixed" id="infinite-scroller">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
  
    <div class="sub-container doc-detail">
  
      <!-- DOCUMENTS REQUESTS DETAIL TEMPLATE ============================================
      ============================================================= -->
      <div *ngIf="!isUser && reference">
        <header class="card">
          <button mat-icon-button color="warn" class="document-back" (click)="goBack()">
            <mat-icon aria-label="back">keyboard_arrow_left</mat-icon>
          </button>
          <div class="group">
            <h3 class="title">{{ reference.title }}</h3>
            <h4 class="subtitle">{{ reference.office?.name }}</h4>
          </div>
  
          <div class="spacer"></div>
          
          <div class="end-button">
            <ng-container *ngTemplateOutlet="action_status_template"></ng-container>
          </div>
        </header>
        <main>
          <article>
            <button (click)="showOldDocumentInfo()" mat-raised-button class="btn-old-info" 
            *ngIf="isAllowOldInfoButton && (request.requestor?._id != user_id)" style="margin-right: -10px;">
              <mat-icon>open_in_new</mat-icon> {{'all_documents.detail_dialog.button.view_old' | translate}}
            </button>
            <mat-chip-list class="ref-chip">
              <mat-chip class="m-t-20 m-b-20 cabinet" *ngIf="reference.qr">{{ reference.qr }}</mat-chip>
            </mat-chip-list>
            
            <!-- <section>
            
              <header>
                <mat-icon>error</mat-icon>
                <span class="m-l-10">{{'all_documents.detail_dialog.reason' | translate}}</span>
              </header>
              <p class="summary" *ngIf="request.description; else emptyDescription">
                {{ request.description }}
              </p>
              <ng-template #emptyDescription>
                <mat-chip class="m-t-20 m-b-20 bg-warn">{{ 'global.empty' | translate }}</mat-chip>
              </ng-template>
            </section> -->

            <!-- ឯកសារ -->
            <!-- <section class="view-file" *ngIf="!(isDeleteRequest && isApproved)">
              <header>
                <mat-icon>file_open</mat-icon>
                <span class="m-l-10">{{ 'all_documents.detail_dialog.files' | translate }}</span>
              </header>
              <mat-expansion-panel *ngFor="let file of reference.files; let i = index" [expanded]="i == 0">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ file.name }}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{ file.pages }} {{ 'global.pages' | translate }}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <app-file-viewer [id]="file._id"></app-file-viewer>
              </mat-expansion-panel>
            </section> -->

            <!-- កម្មវត្ថុ -->
            <!-- <section>
              <header>
                <mat-icon>people</mat-icon>
                <span class="m-l-10">{{ 'requests.type.' + request.type.name + "_lbl" | lowercase | translate }}</span>
              </header>
                  <mat-chip class="m-t-20 m-b-20 cabinet">
                    <a class="inchip-link" [routerLink]="'/users/management/detail/' + request.requestor._id">{{ request.requestor.first_name }} {{ request.requestor.last_name }}</a>
                  </mat-chip>
            </section> -->
            <section>
              <header>
                <mat-icon>summarize</mat-icon>
                <span class="m-l-10">{{ 'all_documents.objective' | translate }}</span>
              </header>
              <p class="summary" *ngIf="reference.objective; else emptySummary">
                {{ reference.objective }}
              </p>
            </section>

            <!-- សង្ខេប -->
            <section>
              <header>
                <mat-icon>article</mat-icon>
                <span class="m-l-10">{{'all_documents.detail_dialog.summary' | translate}}</span>
              </header>
              <p class="summary" *ngIf="reference.summary; else emptySummary">
                {{ reference.summary }}
              </p>
              <ng-template #emptySummary>
                <mat-chip class="m-t-20 m-b-20 bg-warn">{{ 'global.empty' | translate }}</mat-chip>
              </ng-template>
            </section>
  
            <!-- លេខយោង -->
            <section>
              <header>
                <mat-icon>vpn_key</mat-icon>
                <span class="m-l-10">{{ 'all_documents.detail_dialog.ref_id' | translate }}</span>
              </header>
              <mat-chip class="m-t-20 m-b-20 cabinet" [ngClass]="{'bg-warn': reference.reference_id == ''}">{{ reference.reference_id || 'global.empty' | translate }}</mat-chip>
            </section>
  
            <!-- កាលបរិច្ឆេទឯកសារ -->
            <section *ngIf="reference.date">
              <header>
                <mat-icon>schedule</mat-icon>
                <span class="m-l-10">{{'all_documents.detail_dialog.date' | translate}}</span>
              </header>
              <mat-chip class="m-t-20 m-b-20 cabinet">{{translateDate(reference.date | date: 'dd-MMMM-yyyy')}}</mat-chip>
            </section>
  
            <!-- ប្រភេទ -->
            <section>
              <header>
                <mat-icon>category</mat-icon>
                <span class="m-l-10">{{'all_documents.detail_dialog.type' | translate}}</span>
              </header>
              <mat-chip-list>
                <mat-chip class="m-t-20 m-b-20 keyword">
                  {{ reference.type?.type }}
                </mat-chip>
              </mat-chip-list>
            </section>
  
            <!-- អង្គភាព -->
            <section>
              <header>
                <mat-icon>store_mall_directory</mat-icon>
                <span class="m-l-10">{{'all_documents.detail_dialog.office' | translate}}</span>
              </header>
              <mat-chip class="m-t-20 m-b-20 keyword">
                {{ reference.office?.name }}
              </mat-chip>
            </section>
  
            <!-- លេខកូដទូឯកសារ -->
            <section *ngIf="reference.cabinet && !isStateCopy">
              <header>
                <mat-icon>dns</mat-icon>
                <span class="m-l-10">{{'all_documents.detail_dialog.cabinet' | translate}}</span>
              </header>
              <mat-chip-list>
                <mat-chip class="m-t-20 m-b-20 cabinet">{{reference.cabinet.code}}</mat-chip>
              </mat-chip-list>
            </section>
            <ng-template [ngIf]="reference.folders.length">
              <section>
                <header>
                  <mat-icon>inbox</mat-icon>
                  <span class="m-l-10">{{'all_documents.detail_dialog.box' | translate}}</span>
                </header>
                <mat-chip-list>
                  <mat-chip *ngFor="let box of removeDuplicatedArray(documentBoxes)" class="m-t-20 m-b-20 cabinet">{{ box }}</mat-chip>
                </mat-chip-list>
              </section>
  
              <section>
                <header>
                  <mat-icon>folder</mat-icon>
                  <span class="m-l-10">{{'all_documents.detail_dialog.folder' | translate}}</span>
                </header>
                <mat-chip-list>
                  <mat-chip *ngFor="let folder of removeDuplicatedArray(documentFolders)" class="m-t-20 m-b-20 cabinet">{{ folder }}</mat-chip>
                </mat-chip-list>
              </section>
            </ng-template>
  
            <!-- លក្ខណៈឯកសារ -->
            <section *ngIf="reference.states">
              <header>
                <mat-icon>bookmark</mat-icon>
                <span class="m-l-10">{{ 'new_doc.state' | translate }}</span>
              </header>
              <mat-chip-list>
                <mat-chip class="m-t-20 m-b-20 cabinet" *ngFor="let state of reference.states">{{ lang != 'en' ? state.name_kh : state.name }}</mat-chip>
              </mat-chip-list>
            </section>
  
            <!-- ស្ថានភាពឯកសារ -->
            <section *ngIf="reference.quality">
              <header>
                <mat-icon>label_important</mat-icon>
                <span class="m-l-10">{{ 'new_doc.quality' | translate }}</span>
              </header>
              <mat-chip-list>
                <mat-chip class="m-t-20 m-b-20 cabinet">{{ lang != 'en' ? reference.quality.name_kh : reference.quality.name }}</mat-chip>
              </mat-chip-list>
            </section>
  
            <!-- សិទ្ធិប្រើប្រាស់ឯកសារ -->
            <section *ngIf="reference.accessibilities">
              <header>
                <mat-icon>accessibility</mat-icon>
                <span class="m-l-10">{{ 'new_doc.accessibility' | translate }}</span>
              </header>
              <mat-chip-list>
                <mat-chip class="m-t-20 m-b-20 cabinet" *ngFor="let access of reference.accessibilities">{{ lang != 'en' ? access.name_kh : access.name }}
                </mat-chip>
              </mat-chip-list>
            </section>
  
            <!-- ពាក្យគន្លឹះ -->
            <section>
              <header>
                <mat-icon>local_offer</mat-icon>
                <span class="m-l-10">{{'all_documents.detail_dialog.keywords' | translate}}</span>
              </header>
              <mat-chip-list *ngIf="reference.keywords; else keywordEmpty">
                <mat-chip *ngFor="let keyword of reference.keywords.split(',')" class="m-t-20 m-b-20 keyword">
                  {{ keyword }}
                </mat-chip>
              </mat-chip-list>
              <ng-template #keywordEmpty>
                <mat-chip class="m-t-20 m-b-20 bg-warn">
                  {{ 'global.empty' | translate }}
                </mat-chip>
              </ng-template>
            </section>
  
            <!-- កាលបរិច្ឆេទផុតកំណត់ -->
            <section>
              <header>
                <mat-icon>timer</mat-icon>
                <span class="m-l-10">{{'all_documents.detail_dialog.exp_date' | translate}}</span>
              </header>
              <mat-chip *ngIf="reference.expired_date; else notExpiredDate" class="m-t-20 m-b-20 cabinet">{{ translateDate(reference.expired_date | date: 'dd-MMMM-yyyy') }}</mat-chip>
              <ng-template #notExpiredDate>
                <mat-chip class="m-t-20 m-b-20 bg-warn">{{ 'global.empty' | translate }}</mat-chip>
              </ng-template>
            </section>
  
            <!-- កាលបរិច្ឆេទជម្រះចេញពីបណ្ណសារ -->
            <section>
              <header>
                <mat-icon>timelapse</mat-icon>
                <span class="m-l-10">{{'all_documents.detail_dialog.clear_date' | translate}}</span>
              </header>
              <mat-chip class="m-t-20 m-b-20 cabinet" *ngIf="reference.clear_date; else clearDateEmpty">{{translateDate(reference.clear_date | date: 'dd-MMMM-yyyy')}}</mat-chip>
              <ng-template #clearDateEmpty>
                <mat-chip class="m-t-20 m-b-20 bg-warn">{{'global.empty' | translate}}</mat-chip>
              </ng-template>
            </section>
  
            <!-- បញ្ជីអត្តលេខមន្ត្រី -->
            <section>
              <header>
                <mat-icon>verified_user</mat-icon>
                <span class="m-l-10">{{'new_doc.personal_codes_input' | translate}}</span>
              </header>
              <!-- <mat-chip-list>
                <mat-chip class="m-t-20 m-b-20 cabinet" *ngFor="let id of reference.id_cards">{{ id }}</mat-chip>
              </mat-chip-list> -->
              <table *ngIf="reference.id_cards.length > 0 && (user_role_id != ROLES.READER && user_role_id != ROLES.OFFICER); else emptyID" [dataSource]="reference.id_cards" mat-table style="width: 100%;">
                <ng-container matColumnDef="no">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'new_doc.dialog.select.table.no' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let i = index">
                      {{ i + 1 }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="code" #selectedUsersTable>
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'new_doc.dialog.select.table.code' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let user">
                    {{ user.personal_code }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="username">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'new_doc.dialog.select.table.username' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let user">
                      {{ user.last_name + " " + user.first_name }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="office" #selectedUsersTable>
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'new_doc.dialog.select.table.office' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let user">
                        {{ user.office.name }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="selectUserDisplayCol"></tr>
                <tr mat-row *matRowDef="let row; columns: selectUserDisplayCol"></tr>
              </table>
              <ng-template #emptyID>
                <mat-chip class="m-t-20 m-b-20 bg-warn">{{ 'global.empty' | translate }}</mat-chip>
              </ng-template>
            </section>
  
            <!-- ឯកសារ -->
            <section *ngIf="reference.files?.length != 0">
              <header>
                <mat-icon>insert_drive_file</mat-icon>
                <span class="m-l-10">{{'all_documents.detail_dialog.files' | translate}}</span>
              </header>
              <ul class="upload-list">
                <li *ngFor="let progress of reference.files; let i = index" class="file-item">
                  <div class="content content-border">
                    <div class="file-container">
                      <div class="size-box">
                        <h5>
                          <mat-icon>attach_file</mat-icon> {{progress.name}}
                        </h5>
                        <mat-chip-list>
                          <mat-chip *ngIf="progress.is_public" class="public"><mat-icon>public</mat-icon> {{'global.public' | translate}}</mat-chip>
                        </mat-chip-list>
                        <p class="red m-l-10">({{ progress.pages }} {{ progress.pages > 1 ? ('global.pages' | translate) : ('global.page' | translate) }})</p>
                      </div>
                      <div class="size-box" *ngIf="!isDeleteRequest">
                        <button mat-raised-button color="accent" (click)="viewFile(progress)">
                          <mat-icon>remove_red_eye</mat-icon> {{'all_documents.view' | translate}}
                        </button>
                        
                        <button mat-raised-button color="accent" [disabled]="progress.isDownloading" (click)="downloadFile(progress)">
                          <mat-icon>get_app</mat-icon> {{'all_documents.dowload' | translate}}
                        </button>

                        <!-- <button color="accent" mat-icon-button (click)="viewFile(progress)">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <button [disabled]="progress.isDownloading" color="accent" class="download-file" mat-icon-button (click)="downloadFile(progress)">
                          <mat-icon>get_app</mat-icon>
                        </button> -->
                      </div>
                    </div>
                    <mat-progress-bar
                      class="m-t-10"
                      color="accent"
                      [value]="progress.downloadProgress || 0">
                    </mat-progress-bar>
                  </div>
                </li>
              </ul>
              <div class="flex x-end">
                <p class="red">{{'global.total' | translate}}: {{ reference.pages }} {{reference.pages > 1 ? ('global.pages' | translate) : ('global.page' | translate)}}</p>
              </div>
            </section>
  
            <!-- ស្នើដោយ -->
            <section>
              <header>
                <mat-icon>person</mat-icon>
                <span class="m-l-10">{{ 'requests.type.' + request.type.name + '_lbl' | lowercase | translate }}</span>
              </header>
              <table class="requestor-tbl">
                <tr>
                  <td><span class="lbl-info main-lbl">{{ 'requests.requestor.name' | translate }}</span></td>
                  <td class="sign-td"> : </td>
                  <td><a [routerLink]="'/users/management/detail/' + request.requestor._id"><strong>{{ request.requestor.last_name }} {{ request.requestor.first_name }}</strong></a></td>
                </tr>
                <tr>
                  <td><span class="lbl-info main-lbl">{{ 'requests.requestor.office' | translate }}</span></td>
                  <td class="sign-td"> : </td>
                  <td><strong>{{ request.requestor.office.name }}</strong></td>
                </tr>
                <tr>
                  <td><span class="lbl-info main-lbl">{{ 'requests.requestor.date' | translate }}</span></td>
                  <td class="sign-td"> : </td>
                  <td><strong>{{ translateDate(request.createdAt | date: 'dd-MMMM-yyyy hh:mm a', '-') }}</strong></td>
                </tr>
                <!-- <tr>
                  <td><span class="lbl-info main-lbl">{{ 'requests.for' | translate }}</span></td>
                  <td>: <strong></strong></td>
                </tr> -->
                <tr *ngIf="request.description">
                  <td><span class="lbl-info main-lbl">{{ 'requests.requestor.description' | translate }}</span></td>
                  <td class="sign-td"> : </td>
                  <td><strong>{{ request.description }}</strong></td>
                </tr>
              </table>
            </section>

            <!-- ការតា​មដាន -->
            <section *ngIf="trackingList.length > 0 && (user_role_id != ROLES.READER && user_role_id != ROLES.OFFICER)">
              <header>
                <mat-icon>info</mat-icon>
                <span class="m-l-10">{{ 'all_documents.detail_dialog.tracking' | translate }}</span>
              </header>

              <div class="tracking-list">
                <ng-template ngFor let-tracking [ngForOf]="trackingList" let-i="index">
                  <div class="tracking-containers">
                    <div class="tracking-header">
                      <div class="tracking-update-container" style="text-align: end;">
                        <h3 class="m-y-5">
                          {{ tracking.reference.status.status_name === 'pending' ? 
                            translateDate(tracking.createdAt | date:'dd-MMMM-yyyy hh:mm a') : 
                            translateDate(tracking.updatedAt | date: 'dd-MMMM-yyyy hh:mm a') 
                          }}
                        </h3>
                      </div>
                      <div class="tracking-dot-list">
                        <div [class]="'tracking-dot ' + tracking.reference.status.status_name"></div>
                        <div class="tracking-vert"></div>
                        <ul class="tracking-list">
                          <li *ngIf="tracking.reference.status.status_name === 'rejected'">
                            <p class="m-y-5">
                              <span>{{ 'all_documents.tracking.reason' | translate }}</span> 
                              <span class="highlight">{{ tracking.reference.reason || "all_documents.tracking.no_reason" | translate }}</span>
                            </p>
                          </li>
                        </ul>
                      </div>
                      <h3>
                        <span *ngIf="tracking.type == trackingRequestType.APPROVE_REQUEST">{{ 'request_tracking.type.Approve_Request' | translate }}</span>
                        <span *ngIf="tracking.type == trackingRequestType.REJECT_REQUEST">{{ 'request_tracking.type.Reject_Request' | translate }}</span>
                        <span *ngIf="tracking.type == trackingRequestType.VERIFY_REQUEST">{{ 'request_tracking.type.Verify_Request' | translate }}</span>
                        <span *ngIf="tracking.type == trackingRequestType.UPDATE_REQUEST">{{ 'request_tracking.type.Update_Request' | translate }}</span>
                        <span class="highlight">{{ tracking.user?.last_name + " " + tracking.user?.first_name }}</span>
                      </h3>
                    </div>
                  </div>
                </ng-template>
              </div>
            </section>
          </article>
        </main>
      </div>
  
      <!-- Buttons reject and confirm in Request Pending page -->
      <div class="spacer"></div>
      <!-- <div class="button-groups" style="position: relative;">
        <button
          *ngIf="((isPending || isVerified) && (user_role_id == ROLES.ADMIN || user_role_id == ROLES.APPROVER)) || (request.status?.status_name == 'pending' && user_role_id == ROLES.VERIFIER)"
          class="m-r-10 btn-reject" (click)="openReasonDialog()" mat-raised-button>
          <mat-icon>close</mat-icon> {{'notification.button.reject' | translate}}
        </button>
        <button mat-raised-button
          *ngIf="((isPending || isVerified) && (user_role_id == ROLES.ADMIN || user_role_id == ROLES.APPROVER)) || (request.status?.status_name == 'pending' && user_role_id == ROLES.VERIFIER)"
          (click)="isPending ? onVerify() : onApprove()"
          [color]="isPending ? request.type.name == requestTypes.UPDATE ? 'accent' : 'warn' : 'accent'">
          <mat-icon>check</mat-icon>{{'notification.button.' + (request.type.name == requestTypes.UPDATE ? 'verified' : request.status.status_name) | translate}}
        </button>
        <mat-chip-list>
          <mat-chip *ngIf="request.status?.status_name == 'pending' && user_role_id != '1'" class="pending-chip">
            {{'notification.pending' | translate}}</mat-chip>
        </mat-chip-list>
  
      </div> -->
      <!-- End of button reject and confirm in Request Pending Page -->
  
  
      <!-- USER REQUESTS DETAIL TEMPLATE ========================================
      ================================================================= -->
      <div class="profile" *ngIf="isUser && request && reference">
        <div class="row user-head">
          <button mat-icon-button color="warn" class="user-back m-r-10" (click)="goBack()">
            <mat-icon aria-label="back">keyboard_arrow_left</mat-icon>
          </button>
          <h3 class="title">{{'top_menu.profile.request_title' | translate}}</h3>
          
          <div class="spacer"></div>
  
          <div class="end-button">
            <ng-container *ngTemplateOutlet="action_status_template"></ng-container>
          </div>
        </div>
  
        <div>
          <button (click)="showOldUserInfo()" mat-raised-button class="btn-old-info" *ngIf="isAllowOldInfoButton">
            <mat-icon>open_in_new</mat-icon> {{'all_documents.detail_dialog.button.view_old' | translate}}
          </button>
        </div>
  
        <div class="user-container">
          
          <p class="lbl-info" *ngIf="request.description">{{'user.table.reason' | translate}}</p>
          <strong>{{ request.description }}</strong>
          <div class="flex-row center">
            <div class="flex-col grow">
              <p class="lbl-info" *ngIf="reference.first_name">{{'user.create_edit_dialog.form.last_name' | translate}}</p>
              <strong>{{ reference.last_name }}</strong>
        
              <p class="lbl-info" *ngIf="reference.last_name">{{'user.create_edit_dialog.form.first_name' | translate}}</p>
              <strong>{{ reference.first_name }}</strong>
            </div>
            <div class="avatar-container">
              <img *ngIf="!reference.avatar" class="avatar" src="/assets/images/nopf.png" [alt]="reference.last_name + ' ' + reference.first_name">
              <img *ngIf="reference.avatar" class="avatar" [src]="referenceAvatar" [alt]="reference.last_name + ' ' + reference.first_name">
            </div>
          </div>
          <p class="lbl-info" *ngIf="reference.position.name">{{'user.table.position' | translate}}</p>
          <strong>{{ lang != 'en' ? reference?.position.name_kh : reference?.position.name}}</strong>
    
          <p class="lbl-info" *ngIf="reference.role.name">{{'top_menu.profile.role' | translate}}</p>
          <strong>{{ lang != 'en' ? reference.role.name_kh : reference.role.name }}</strong>
    
          <p class="lbl-info" *ngIf="reference.office.name">{{'top_menu.profile.office' | translate}}</p>
          <strong>{{ reference.office.name }}</strong>
    
          <p class="lbl-info" *ngIf="reference.email">{{'user.table.email' | translate}}</p>
          <strong>{{ reference?.email }}</strong>
    
          <p class="lbl-info" *ngIf="reference.personal_code">{{'new_doc.personal_codes_input' | translate}}</p>
          <strong>{{ reference?.personal_code }}</strong>
    
          <ng-container *ngIf="reference?.manage_office_ids?.length">
            <p class="lbl-info">{{'user.create_edit_dialog.form.choose_office' | translate}}</p>
            <ng-template #officeList let-list>
              <ng-container *ngFor="let office of list.data">
      
                <div [style.padding-left]=" 20 * list.index + 'px'" class="row-office">
                  <mat-icon *ngIf="office.childs.length">arrow_drop_down</mat-icon>
                  <div class="choose-office" [style.padding-left]="office.childs.length ? '0px' : '24px'">
                    <span>{{office.name}}</span>
                  </div>
                </div>
      
                <ng-container *ngIf="office.childs">
                  <ng-container
                    *ngTemplateOutlet="officeList; context:{ $implicit: { data: office.childs, index: list.index + 1, parent: office, collapse: office.collapse }}">
                  </ng-container>
                </ng-container>
      
              </ng-container>
            </ng-template>
      
            <ng-container
              *ngTemplateOutlet="officeList; context:{ $implicit: {data: offices, index: 0, parent: null, collapse: true}}">
            </ng-container>
          </ng-container>
    
          <ng-container *ngIf="request.requestor?._id != user_id">
            <p class="lbl-info">{{ 'requests.type.' + requestTypeLocalize + '_lbl' | lowercase | translate }}</p>
            <div>
              <table class="requestor-tbl">
                <tr>
                  <td><span class="lbl-info main-lbl">{{ 'requests.requestor.name' | translate }}</span></td>
                  <td>: <strong>{{ request.requestor.last_name }} {{ request.requestor.first_name }}</strong></td>
                </tr>
                <tr>
                  <td><span class="lbl-info main-lbl">{{ 'requests.requestor.office' | translate }}</span></td>
                  <td>: <strong>{{request.requestor.office.name}}</strong></td>
                </tr>
                <tr>
                  <td><span class="lbl-info main-lbl">{{ 'requests.requestor.date' | translate }}</span></td>
                  <td>: <strong>{{ translateDate(request.createdAt | date:  'dd-MMMM-yyyy hh:mm a') }}</strong></td>
                </tr>
              </table>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="submitLoading" class="loading">
      <mat-spinner color="accent" [diameter]="50"></mat-spinner>
    </div>
  </div>
  
  <!-- Actions and Statuses Template -->
  <ng-template #action_status_template>
    <mat-chip-list>
      <!-- Pending for requestor (Post Officer | Writer) -->
  
      <mat-chip color="warn" selected class="pending-chip"
        *ngIf="isPending && request.type?.name == 'Verify' && (user_role_id == ROLES.WRITER || user_role_id == ROLES.POST_OFFICER)">
        {{ 'requests.status.pending' | translate }}
      </mat-chip>
  
      <mat-chip color="warn" selected class="verified-chip"
        *ngIf="isVerified && request.type?.name == 'Update' && (user_role_id == ROLES.WRITER || user_role_id == ROLES.POST_OFFICER)">
        {{ 'requests.status.requested_for_editing' | translate }}
      </mat-chip>

      <mat-chip color="warn" selected class="verified-chip"
        *ngIf="isVerified && isDeleteRequest && (user_role_id == ROLES.WRITER || user_role_id == ROLES.POST_OFFICER)">
        {{ 'requests.status.requested_for_editing' | translate }}
      </mat-chip>

      <mat-chip color="warn" selected class="verified-chip"
        *ngIf="isVerified && isResetPassword && (user_role_id == ROLES.WRITER || user_role_id == ROLES.POST_OFFICER)">
        {{ 'requests.status.requested_for_editing' | translate }}
      </mat-chip>
      
      <mat-chip color="warn" selected class="pending-chip"
        *ngIf="isPending && request.type?.name == 'Create' && (user_role_id == ROLES.WRITER || user_role_id == ROLES.POST_OFFICER || (request.requestor._id == user_id && (user_role_id == ROLES.VERIFIER || user_role_id == ROLES.APPROVER)))">
        {{ 'requests.status.pending' | translate }}
      </mat-chip>
  
  
      <!-- Pending for requestor (Verifier | Approver) -->
      <!-- OLD -->
      <mat-chip color="warn" selected class="pending-chip"
        *ngIf="isPending && request.type?.name == 'Verify' && (user_role_id == ROLES.APPROVER || user_role_id == ROLES.VERIFIER) && (request.requestor?._id == user_id)">
        {{ 'requests.status.pending' | translate }}
      </mat-chip> 
      <mat-chip color="warn" selected class="verified-chip"
        *ngIf="isVerified && isDeleteRequest && (user_role_id == ROLES.APPROVER || user_role_id == ROLES.VERIFIER) && (request.requestor?._id == user_id)">
        {{ 'requests.status.requested_for_editing' | translate }}
      </mat-chip>

      <mat-chip color="warn" selected class="verified-chip"
        *ngIf="isVerified && isResetPassword && (user_role_id == ROLES.APPROVER || user_role_id == ROLES.VERIFIER || user_role_id == ROLES.ADMIN) && (request.requestor?._id == user_id)">
        {{ 'requests.status.requested_for_editing' | translate }}
      </mat-chip>
  
      <!-- OLD -->
      <mat-chip color="warn" selected class="verified-chip"
        *ngIf="isVerified && request.type?.name == 'Update' && (user_role_id == ROLES.APPROVER || user_role_id == ROLES.VERIFIER) && (request.requestor?._id == user_id)">
        {{ 'requests.status.requested_for_editing' | translate }}
      </mat-chip>
      <!-- <mat-chip color="warn" selected class="verified-chip"
        *ngIf="isVerified && request.type.name == 'Update' && (user_role_id == ROLES.APPROVER || user_role_id == ROLES.VERIFIER) && request.requestor?._id == user_id">
        {{ 'requests.status.requested_for_editing' | translate }}
      </mat-chip> -->
  
  
      <!-- Verified for requestor (Post Officer | Writer | Verifier) -->
  
      <mat-chip class="verified-chip"
        *ngIf="isVerified && (request.type.name == 'Verify' || request.type.name == 'Create') && (user_role_id == ROLES.WRITER || user_role_id == ROLES.VERIFIER || user_role_id == ROLES.POST_OFFICER || (user_role_id == ROLES.APPROVER && isYourReq))">
        {{ 'requests.status.verified' | translate }}
      </mat-chip>
      <mat-chip class="verified-chip"
        *ngIf="isVerified && request.type.name == 'Verify' && (user_role_id == ROLES.APPROVER || user_role_id == ROLES.ADMIN) && (request.requestor?._id == user_id)">
        {{ 'requests.status.verified' | translate }}
      </mat-chip>
  
      <!-- <mat-chip class="verified-chip"
        *ngIf="isVerified && (request.type?.name == 'Verify' || request.type.name == 'Create') && (user_role_id == ROLES.VERIFIER || user_role_id == ROLES.POST_OFFICER || user_role_id == ROLES.WRITER)">
        {{ 'requests.status.pending' | translate }}
      </mat-chip> -->
      
      <!-- Verified for requestor (Verifier | Approver) -->
  
      <!-- <mat-chip class="verified-chip"
        *ngIf="isVerified && (user_role_id == ROLES.APPROVER || user_role_id == ROLES.VERIFIER) && request.requestor?._id == user_id">
        {{ 'requests.status.verified' | translate }}
      </mat-chip> -->
      
      
  
      <!-- Approved for all requestor types (Post Officer | Writer | Verifier | Approver | *Admin) -->
  
      <mat-chip color="accent" selected class="success-chip"
        *ngIf="request.status.status_name == 'approved' && (user_role_id == ROLES.ADMIN || user_role_id == ROLES.APPROVER || user_role_id == ROLES.VERIFIER || user_role_id == ROLES.WRITER || user_role_id == ROLES.POST_OFFICER)">
        {{ 'requests.status.approved' | translate}}
      </mat-chip>
      
  
      <!-- Rejected for all requestor types (Post Officer | Writer | Verifier | Approver | *Admin) -->
  
      <ng-container *ngIf="request.status.status_name == 'rejected' && (user_role_id == ROLES.ADMIN || user_role_id == ROLES.APPROVER || user_role_id == ROLES.VERIFIER || user_role_id == ROLES.WRITER || user_role_id == ROLES.POST_OFFICER)">
        <mat-icon class="info" matTooltip="{{ request.reason }}" matTooltipClass="info-tooltip">info</mat-icon>
        <mat-chip class="error-chip">
          {{ 'requests.status.rejected' | translate }}
        </mat-chip>
      </ng-container>
  
    </mat-chip-list>
  
    <div class="btn-container">
      <button (click)="openEditUserDialog()" color="accent" mat-raised-button *ngIf="isUser && isRejected && isYourReq && reference.is_editable; else docEdit">
        <mat-icon>edit</mat-icon>
        {{'all_documents.detail_dialog.button.edit' | translate}}
      </button>
      <ng-template #docEdit>
        <a mat-raised-button color="accent" [routerLink]="'/requests/document/edit/' + request._id" *ngIf="(isRejected) && (isYourReq) && reference.is_editable">
          <mat-icon>edit</mat-icon> {{'all_documents.detail_dialog.button.edit' | translate}}
        </a>
      </ng-template>
      <!-- <button mat-raised-button
        *ngIf="(request.status?.status_name == 'rejected') && (request.requestor?._id == user_id) && request.reference?.is_editable"
        (click)="goToEdit(request.original_id ? request.original_id : request._id)" color="accent">
        <mat-icon>edit</mat-icon> {{'all_documents.detail_dialog.button.edit' | translate}}
      </button> -->
      <!-- OLD -->
      <!-- <button mat-raised-button
        *ngIf="(((isPending || isVerified) && (user_role_id == ROLES.ADMIN || user_role_id == ROLES.APPROVER)) || (request.status?.status_name == 'pending' && user_role_id == ROLES.VERIFIER)) && (request.requestor?._id != user_id)"
        class="m-r-10 btn-reject" (click)="openReasonDialog()">
        <mat-icon>close</mat-icon> {{'notification.button.reject' | translate}}
      </button> -->
      <!-- <button mat-raised-button
        *ngIf="(((isPending || isVerified) && (user_role_id == ROLES.ADMIN || user_role_id == ROLES.APPROVER)) || (isPending && user_role_id == ROLES.VERIFIER)) && (request.requestor._id != user_id)"
        (click)="isPending ? isResetPassword ? onApprove() : onVerify() : onApprove()"
        [color]="isPending ? isUpdateRequest || isDeleteRequest || isResetPassword ? 'accent' : 'warn' : 'accent'">
        <mat-icon>check</mat-icon>
        {{isResetPassword ? ('notification.button.verified' | translate) : 'notification.button.' + ((isUpdateRequest || isDeleteRequest) ? 'verified' : request.status.status_name) | translate}}
      </button> -->
      <button
        mat-raised-button
        *ngIf="(allowVerify || allowApprove) && !isYourReq"
        class="m-r-10 btn-reject"
        (click)="openReasonDialog()"
      >
        <mat-icon>close</mat-icon> {{'notification.button.reject' | translate}}
      </button>
      <button
        mat-raised-button
        *ngIf="(allowVerify || allowApprove) && !isYourReq"
        (click)="isPending ? isResetPassword ? onApprove() : onVerify() : onApprove()"
        [color]="isPending ? isUpdateRequest || isDeleteRequest || isResetPassword ? 'accent' : 'warn' : 'accent'">
          <mat-icon>check</mat-icon>
          {{isResetPassword ? ('notification.button.verified' | translate) : 'notification.button.' + ((isUpdateRequest || isDeleteRequest) ? 'verified' : request.status.status_name) | translate}}
      </button>

      <button
        *ngIf="isAllowUpdate"
        [routerLink]="(this.isYourReq && this.isUpdateRequest) ? ('/documents/edit/' + request.reference._id) : ('/requests/document/edit/' + request._id)"
        class="outline" color="accent"
        mat-raised-button>
        <mat-icon>edit</mat-icon> {{'all_documents.detail_dialog.button.edit' | translate}}
      </button>
    </div>
</ng-template>