import { LocalstorageService } from "./../../../core/services/localstorage.service";
import { NewAPIService } from "./../../../core/services/new-api.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SubmitReasonDialogComponent } from "../../dialogs/submit-reason/submit-reason-dialogcomponent";
import { ConfirmDialogComponent } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { NavService } from "src/app/core/services/nav.service";
import { REQUEST_TYPE } from "src/app/core/models/enum/request-type.enum";
import { MODELS, REFERENCE_MODEL } from "src/app/core/models/enum/model.enum";
import { STATUSES } from "src/app/core/models/enum/request_status.enum";
import { AuthService } from "src/app/core/services/auth.service";
import { LocalStorageEnum } from "src/app/core/models/enum/local-storage.enum";

@Component({
  selector: "app-tracking-item",
  templateUrl: "./tracking-item.component.html",
  styleUrls: ["./tracking-item.component.scss"],
})
export class TrackingItemComponent implements OnInit {
  lang: string = "";
  fileCount = 0;
  hideFiles = true;
  user_role_id: string;
  requestTypes = REQUEST_TYPE;
  models = MODELS;
  STATUSE_TYPE = STATUSES;
  reference: any = {};
  isUser: boolean = false;
  @Input() item: any = {};
  @Input() index: number = 1;
  @Input() request: "user" | "document" | "delete_document";
  @Input() isYourRequest: any;
  @Input() isRejected: any;

  @Output() reload = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private newApi: NewAPIService,
    private authService: AuthService,
    private ls: LocalstorageService,
    private translate: TranslateService,
    private nav: NavService
  ) {
    let user = JSON.parse(this.ls._getDecryption(LocalStorageEnum.user));
    this.user_role_id = user.role._id.toString();
  }

  ngOnInit() {
    this.lang = this.authService.getLocale();
    this.isUser =
      this.item.onModel == MODELS.TEMPORARY
        ? this.item.tmp_model == REFERENCE_MODEL.USER
        : this.item.onModel == REFERENCE_MODEL.USER;
    this.reference = this.item.reference;
  }

  onVerify(close: () => void) {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: "450px",
        data: {
          isNegative: false,
          title: this.translate.instant("approve.title"),
          subtitle: this.translate.instant("approve.question"),
          positiveText: this.translate.instant("approve.postive"),
          negativeText: this.translate.instant("cancel"),
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (!response) {
          close();
          return;
        }
        if (this.item.type.name === this.requestTypes.UPDATE)
          this.newApi.approveRequest(this.item._id).subscribe((response) => {
            if (response.status) {
              this.nav.updatePendingCount();
              this.reload.emit(true);
            }
          });
        else
          this.newApi.verifyRequest(this.item._id).subscribe((res: any) => {
            if (res) {
              this.nav.updatePendingCount();
              this.reload.emit(true);
            }
          });
      });
  }

  onApprove(close?: () => void): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: "450px",
        data: {
          isNegative: false,
          title: this.translate.instant("approve.title"),
          subtitle: this.translate.instant("approve.question"),
          positiveText: this.translate.instant("approve.postive"),
          negativeText: this.translate.instant("cancel"),
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (!response) {
          close();
          return;
        }
        this.newApi.approveRequest(this.item._id).subscribe((response) => {
          if (response.status) {
            this.nav.updatePendingCount();
            this.reload.emit(true);
          }
        });
      });
  }

  openReasonDialog(request: any) {
    this.dialog
      .open<SubmitReasonDialogComponent>(SubmitReasonDialogComponent, {
        panelClass: "less-shadow",
        width: "450px",
        data: {
          isNegative: true,
          request,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.nav.updatePendingCount();
          this.reload.emit(true);
        }
      });
  }

  onSelectItem(index: number) {
    this.ls.set(LocalStorageEnum.selected_item, index.toString());
  }

  translateDate(dateTime: string, spacer: string = "-"): string {
    const date = dateTime.split(" ")[0].split("-");
    const time = dateTime.split(" ")[1]
      ? dateTime.split(" ")[1].split(":")
      : "";
    dateTime = `${date[0]}${spacer}${this.translate.instant(
      "months." + date[1]
    )}${spacer}${date[2]} ${Array.isArray(time) ? time.join(":") : time} ${
      dateTime.split(" ")[2] || ""
    }`;
    return dateTime;
  }
}
