import { LocalstorageService } from './localstorage.service';
import { Router } from '@angular/router';
import { CommonResponse } from './../models/responses/common.response';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageEnum } from '../models/enum/local-storage.enum';
import { SERVER_STATUS } from '../models/enum/server_response.enum';


@Injectable({
  providedIn: 'root'
})
export class NewRequestService {

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private router: Router,
    private ls: LocalstorageService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  postJSON<T>(url: string, data: any = {}, options?: object, is_show_msg: boolean = true, noCache = false) { // <T> is use to make IDE spot the type missmatch of input data
    this.clean(data); 
    this.loadingService.setLoading(true);

    const headers = this.getAuthHeader(data.is_refresh_token ? true : false, noCache);
    headers.append('Content-Type', 'application/json'); 

    let _data = data.is_refresh_token ? {} : data;

    return this.http.post<T>(url, _data, { headers, ...options, }) 
    .pipe(
      map(res => {
        const r = res as unknown as CommonResponse;
        if (is_show_msg && r.status == 1) this.snackBar.open(this.translate.instant('new_request_service.success'), '', {duration: 3000, horizontalPosition: 'center', panelClass: 'panelClass-success'});
        this.loadingService.setLoading(false);
        this.handleError(res);
        return res;
      }),
      catchError((err) => this.handleHttpError(err))
    );
  }

  postJSONWithoutMessage<T>(url: string, data: any, options?: object) {
    this.clean(data);
    this.loadingService.setLoading(true);

    const headers = this.getAuthHeader(data.is_refresh_token ? true : false);
    headers.append('Content-Type', 'application/json');

    let _data = data.is_refresh_token ? {} : data;

    return this.http.post<T>(url, _data, { headers, ...options, })
    .pipe(
      map(res => {
        const r = res as unknown as CommonResponse;
        this.loadingService.setLoading(false);
        this.handleError(res);
        return res;
      }),
      catchError((err) => this.handleHttpError(err))
    );
  }

  post<T>(url, data: FormData, message: boolean = true) {
    this.loadingService.setLoading(true);
    const headers = this.getAuthHeader();
    return this.http.post<T>(url, data, {
      headers
    })
    .pipe(
      map(res => {
        const r = res as unknown as CommonResponse;
        if (r.status == 1 && message)
          this.snackBar.open(this.translate.instant('new_request_service.success'), '', {duration: 3000, horizontalPosition: 'center', panelClass: 'panelClass-success'});
        this.loadingService.setLoading(false);
        this.handleError(res);
        return res;
      }),
      catchError((err) => this.handleHttpError(err))
    );
  }
  postUrlEncoded<T>(url, data: URLSearchParams) {
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<T>(url, data, { headers })
    .pipe(
      map(res => {
        const r = res as unknown as CommonResponse;
        this.loadingService.setLoading(false);
        this.handleError(res);
        return res;
      }),
      catchError(err => this.handleHttpError(err))
    );
  }
  // postWithoutMessage<T>(url, data?: FormData) 

  patchJSON<T>(url: string, data: any = {}, message: boolean = true) {
    this.clean(data);
    this.loadingService.setLoading(true);
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'application/json');
    return this.http.patch<T>(url, data, { headers }).pipe(
      map(res => {
        const r = res as unknown as CommonResponse;
        r.status == SERVER_STATUS.SUCCESS && message && this.snackBar.open(this.translate.instant('new_request_service.success'), '', {duration: 3000, horizontalPosition: 'center', panelClass: 'panelClass-success'});
        this.loadingService.setLoading(false);
        this.handleError(res);
        return res;
      }),
      catchError((err) => this.handleHttpError(err))
    );
  }

  putJSON<T>(url: string, data: any = {}, message: boolean = true) {
    this.clean(data);
    this.loadingService.setLoading(true);
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'application/json');
    return this.http.put<T>(url, data, { headers }).pipe(
      map(res => {
        const r = res as unknown as CommonResponse;
        if (r.status == 1 && message)
          this.snackBar.open(this.translate.instant('new_request_service.success'), '', {duration: 3000, horizontalPosition: 'center', panelClass: 'panelClass-success'});
        this.loadingService.setLoading(false);
        this.handleError(res);
        return res;
      }),
      catchError((err) => this.handleHttpError(err))
    );
  }

  getJSON<T>(url: string, data: any = {}, setLoading = true, noCache = false) {
    this.clean(data);
    if (setLoading) {
      this.loadingService.setLoading(true);
    }

    const headers = this.getAuthHeader(false, noCache);
    headers.append('Content-Type', 'application/json');
    return this.http.get<T>(url, { params: data, headers }).pipe(
      map(res => {
        if (setLoading) {
          this.loadingService.setLoading(false);
        }

        this.handleError(res);
        return res;
      }),
      catchError((err) => this.handleHttpError(err))
    );
  }
  getWithoutAuth(url, params: { [key: string]: any } = {}, responseType: any = "blob", progress: boolean = true) {
    return this.http.get(url, {
      params,
      responseType,
      reportProgress: progress,
      observe: progress ? "events" : null
    }).pipe(
      map(
        res => {
          this.handleError(res);
          return res;
        }
      ),
      catchError(err => this.handleHttpError(err))
    );
  }
  get(url: string, data?: any, setLoading=true, progress: boolean = true, responseType: string = "blob", nocache = false) {
  	const headers = this.getAuthHeader(false, nocache);
  	return this.http.get(url, {
  		params: data,
  		headers: headers,
  		responseType: responseType as any,
      reportProgress: progress,
      observe: progress ? 'events' : null
  	}).pipe(
  		map(res => {
  			if (setLoading) {
  				this.loadingService.setLoading(false);
  			}
  			this.handleError(res);
  			return res;
  		}),
  		catchError(err => this.handleHttpError(err))
  	);
  }
  getJSONWithoutMessage<T>(url: string, data: any = {}, setLoading = true) {
    this.clean(data);
    if (setLoading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'application/json');
    return this.http.get<T>(url, { params: data, headers }).pipe(
      map(res => {
        if(setLoading)
          this.loadingService.setLoading(false);
        // this.handleError(res);/
        return res;
      })
    );
  }

  delete<T>(url: string, data: any = {}, message: boolean = false) {
    this.clean(data);
    this.loadingService.setLoading(true);
    const headers = this.getAuthHeader();
    return this.http.delete<T>(url, { params: data, headers }).pipe(
      map(res => {
        const r = res as unknown as CommonResponse;
        if (r.status == 1 && message)
        	this.snackBar.open(this.translate.instant('new_request_service.success'), '', {duration: 3000, horizontalPosition: 'center', panelClass: 'panelClass-success'});
        this.loadingService.setLoading(false);
        this.handleError(res);
        return res;
      }),
      catchError((err) => this.handleHttpError(err))
    );
  }


  private clean(obj: any) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }


  public getAuthHeader(is_refresh_token: boolean = false, noCahce = false): HttpHeaders {
    const token = this.ls.get( is_refresh_token ? LocalStorageEnum.refresh_token : LocalStorageEnum.token);
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token
    });
    if (noCahce) {
      headers.append("Cache-Control", "no-cache");
    }
    return headers;
  }


  private handleError(res: any, msg: boolean = true) {
    const r = res as unknown as CommonResponse;
    if (r.status === 401) {
      // this.snackBar.open(this.translate.instant('expired_token'), '', {duration: 3000, panelClass: 'panelClass-error'});
      localStorage.clear();
      this.router.navigateByUrl('/sign-in');
    }
    if (msg) return;
    else if ((r.status === -1 || r.status === 0) && !r.data) {
      const msg = res.message;
      var baseMsg: string = '';
      this.snackBar.open(msg || this.translate.instant('res_msg.error'), '', { duration: 3000, horizontalPosition: 'center', panelClass: 'panelClass-error' });
    } else if((r.status === -1 || r.status === 0) && r.data) {
      let array_msg = [];
      for(let key in res.data) {
        array_msg.push(res.data[key][0]);
      }
      
      if (array_msg.includes("Parent is invalid value")) {
        baseMsg = this.translate.instant('new_request_service.snackbar.not_cabinet');
      } else if (array_msg.includes("Code already exist")) {
        baseMsg = this.translate.instant('new_request_service.snackbar.exist_code');
      }

      if(!baseMsg && r.status == 0) {
        this.snackBar.open(array_msg[0], '', { duration: 3000, horizontalPosition: 'center', panelClass: 'panelClass-error' });
      } else {
        this.snackBar.open(baseMsg || this.translate.instant('res_msg.error'), '', { duration: 3000, horizontalPosition: 'center', panelClass: 'panelClass-error' });
      }
    }
    else if (r.status === 500) {
      window.alert(this.translate.instant('res_msg.error'));
    }
  }

  private handleHttpError(error: any) {
    this.loadingService.setLoading(false);
    if (error.status === 401) {
      // this.snackBar.open(this.translate.instant('expired_token'), '', {duration: 3000, panelClass: 'panelClass-error'});
      localStorage.clear();
      this.router.navigateByUrl('/sign-in');
    } else {
      this.snackBar.open(this.translate.instant('res_msg.error'), '', {duration: 3000, panelClass: 'panelClass-error'});
    }
    return throwError(error);
  }

  private toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }
    return formData;
  }
}
