<h2 *ngIf="user == null" mat-dialog-title>{{'user.create_edit_dialog.create_title' | translate}}</h2>
<h2 *ngIf="user != null" mat-dialog-title>{{'user.create_edit_dialog.edit_title' | translate}}</h2>
<mat-dialog-content #container>
  <div *ngIf="isLoading" class="loading">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
  <form [formGroup]="formGroup">
    <div class="group-fields">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>{{'user.create_edit_dialog.form.last_name' | translate}}</mat-label>
          <input matInput formControlName="lastName" type="text"
            [placeholder]="'user.create_edit_dialog.form.last_name' | translate" required>
          <mat-error *ngIf="getFormControl('lastName').hasError('required')">
            {{'global.form.can_not_save' | translate}}{{'user.create_edit_dialog.form.last_name' | translate}}{{'global.form.be_empty' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{'user.create_edit_dialog.form.first_name' | translate}}</mat-label>
          <input formControlName="firstName" matInput type="text"
            [placeholder]="'user.create_edit_dialog.form.first_name' | translate" required>
          <mat-error *ngIf="getFormControl('firstName').hasError('required')">
            {{'global.form.can_not_save' | translate}}{{'user.create_edit_dialog.form.first_name' | translate}}{{'global.form.be_empty' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex-end">
        <!-- <input (change)="openImageCropDialog()" type="file" accept="image/*" hidden id="avatars"> -->
        <div class="avatar-container" (click)="openImageCropDialog()" matRipple>
          <img *ngIf="!isEmptyAvatar && !avatarLoading" [src]="avatarUrl">
          <div *ngIf="avatarLoading">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>
          <mat-icon *ngIf="isEmptyAvatar">add_photo_alternate</mat-icon>
          <label for="avatars">
            <div *ngIf="!isEmptyAvatar" class="edit-shadow">
              <mat-icon>edit</mat-icon>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="group">

      <mat-form-field appearance="outline">
        <mat-label>{{'user.create_edit_dialog.form.username' | translate}}</mat-label>
        <input matInput formControlName="username" type="text"
          [placeholder]="'user.create_edit_dialog.form.username' | translate" required>
        <mat-error *ngIf="getFormControl('username').hasError('required')">
          {{'global.form.can_not_save' | translate}}{{'user.create_edit_dialog.form.username' | translate}}{{'global.form.be_empty' | translate}}
        </mat-error>
        <mat-error *ngIf="getFormControl('username').hasError('exist')">
          {{ 'user.create_edit_dialog.form.error.un.exist' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'user.create_edit_dialog.form.dob' | translate }}</mat-label>
        <input autocomplete="bday-day" (focus)="onDatePicker($event, dob)" readonly [max]="maxAge" formControlName="dob" matInput [matDatepicker]="dob">
        <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
        <mat-datepicker #dob></mat-datepicker>
      </mat-form-field>

    </div>

    <mat-form-field appearance="outline">
      <mat-label>{{'user.create_edit_dialog.form.title' | translate}}</mat-label>
      <mat-select formControlName="title">
        <mat-option *ngFor="let item of titles" [value]="item.value">{{ item?.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{'user.create_edit_dialog.form.office' | translate}}</mat-label>
      <mat-select formControlName="office" [placeholder]="'user.create_edit_dialog.form.office' | translate" (selectionChange)="onOfficeChange($event)">

        <ng-template #parentList let-list>
          <ng-container *ngFor="let office of list.data">
            <div *ngIf="list.collapse || isEditing" [style.padding-left]=" 18 * list.index + 12 + 'px'"
              class="tfield color-primary">
              <button style="height: 45px; width: 45px;"
                (click)="office.collapse = !!!office.collapse; clickCollapse(office)"
                [style.visibility]="office.childs.length ? 'show': 'hidden' " mat-icon-button>
                <mat-icon *ngIf="!office.collapse">arrow_right</mat-icon>
                <mat-icon *ngIf="office.collapse">arrow_drop_down</mat-icon>
              </button>
              <!-- <mat-option [disabled]="!checkMangeOffice(office._id)" style="width: calc(100% - 45px); float: right;" [value]="office._id">{{office.name}}
              </mat-option> -->
              <mat-option  style="width: calc(100% - 45px); float: right;" [value]="office._id">{{office.name}}
              </mat-option>
            </div>

            <ng-container *ngIf="office.childs">
              <ng-container
                *ngTemplateOutlet="parentList; context:{ $implicit: { data: office.childs, index: list.index + 1, parent: office, collapse: office.collapse }}">
              </ng-container>
            </ng-container>

          </ng-container>
        </ng-template>

        <ng-container
          *ngTemplateOutlet="parentList; context:{ $implicit: {data: offices, index: 0, parent: null, collapse: true}}">
        </ng-container>

      </mat-select>
      <mat-error *ngIf="getFormControl('office').hasError('required')">
        {{'global.form.can_not_save' | translate}}{{'user.create_edit_dialog.form.office' | translate}}{{'global.form.be_empty' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{'user.create_edit_dialog.form.position' | translate}}</mat-label>
      <mat-select (valueChange)="positionGetChange($event)" formControlName="position"
        [placeholder]="'user.create_edit_dialog.form.position' | translate" required>
        <mat-option *ngFor="let position of positions" [value]="position._id">
          {{ lang != 'en' ? position.name_kh : position.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="getFormControl('position').hasError('required')">
        {{'global.form.can_not_save' | translate}}{{'user.create_edit_dialog.form.position' | translate}}{{'global.form.be_empty' | translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="isShowRoleFormField" appearance="outline">
      <mat-label>{{'user.create_edit_dialog.form.role' | translate}}</mat-label>
      <mat-select required formControlName="role" [placeholder]="'user.create_edit_dialog.form.role' | translate">
        <mat-option *ngFor="let role of roles" [value]="role._id">
          {{ lang != 'en' ? role.name_kh : role.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="getFormControl('role').hasError('required')">
        {{'global.form.can_not_save' | translate}}{{'user.create_edit_dialog.form.role' | translate}}{{'global.form.be_empty' | translate}}
      </mat-error>
    </mat-form-field>

    <ng-template [ngIf]="(isEditing && isCanEditPassword) || !isEditing || (isRequest && data.isCreateReq)">
      <mat-form-field appearance="outline">
        <mat-label>{{'user.create_edit_dialog.form.pwd' | translate}}</mat-label>
        <input
          #pwd
          matInput type="password" formControlName="pwd" autocomplete="new-password"
          [placeholder]="'user.create_edit_dialog.form.pwd' | translate" [required]="!isEditing || pwdControl.value && rePwdControl.value ? true : null">
        <button (click)="toggleVisiblePassword(pwd)" type="button" mat-icon-button matSuffix><mat-icon>{{ pwd.type == "password" ? 'visibility' : 'visibility_off' }}</mat-icon></button>
        <mat-error *ngIf="pwdControl.hasError('pwd-strong')">
          {{ 'user.create_edit_dialog.form.error.pwd_strong' | translate | titlecase }}
        </mat-error>
        <mat-error *ngIf="pwdControl.hasError('pattern')">
          {{ 'user.create_edit_dialog.form.error.pwd_strong' | translate | titlecase }}
        </mat-error>
        <mat-error *ngIf="pwdControl.hasError('required')">
          {{'global.form.can_not_save' | translate}}{{'user.create_edit_dialog.form.pwd' | translate}}{{'global.form.be_empty' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'user.create_edit_dialog.form.re_pwd' | translate}}</mat-label>
        <input
          matInput type="password" [formControl]="rePwdControl" autocomplete="new-password"
          #repwd
          [placeholder]="'user.create_edit_dialog.form.re_pwd' | translate" [required]="!isEditing || pwdControl.value ? true : null">
        <button (click)="toggleVisiblePassword(repwd)" type="button" mat-icon-button matSuffix><mat-icon>{{ repwd.type == "password" ? 'visibility' : 'visibility_off' }}</mat-icon></button>
        <mat-error *ngIf="rePwdControl.hasError('notUnique')">
          {{ 'user.create_edit_dialog.form.error.password_unique' | translate }}
        </mat-error>
        <mat-error *ngIf="rePwdControl.hasError('pattern')">
          {{ 'user.create_edit_dialog.form.error.pwd_strong' | translate | titlecase }}
        </mat-error>
        <mat-error *ngIf="rePwdControl.hasError('pwd-strong')">
          {{ 'user.create_edit_dialog.form.error.pwd_strong' | translate | titlecase }}
        </mat-error>
        <mat-error *ngIf="rePwdControl.hasError('required')">
          {{'global.form.can_not_save' | translate}}{{'user.create_edit_dialog.form.re_pwd' | translate}}{{'global.form.be_empty' | translate}}
        </mat-error>
      </mat-form-field>
    </ng-template>

    <mat-form-field appearance="outline">
      <mat-label>{{'user.create_edit_dialog.form.personal_code' | translate}}</mat-label>
      <input formControlName="personal_code" matInput type="text"
        [placeholder]="'user.create_edit_dialog.form.personal_code' | translate" required>
      <mat-error *ngIf="getFormControl('personal_code').hasError('pattern')">
        {{'user.create_edit_dialog.form.personal_code' | translate}}{{'global.form.invalid' | translate}}
      </mat-error>
      <mat-error *ngIf="getFormControl('personal_code').hasError('required')">
        {{'global.form.can_not_save' | translate}}{{'user.create_edit_dialog.form.personal_code' | translate}}{{'global.form.be_empty' | translate}}
      </mat-error>
      <mat-error *ngIf="getFormControl('personal_code').hasError('exist')">
        {{'user.create_edit_dialog.form.error.pc.exist' | translate}}
      </mat-error>
    </mat-form-field>

    <div class="control-box m-t-10">
      <mat-label>{{ 'new_doc.accessibility' | translate }}</mat-label>
      <div class="row">
        <mat-checkbox formControlName="is_highly_sensitive">
          {{ 'new_doc.highly_sensitive' | translate }}
        </mat-checkbox>
        <mat-checkbox *ngIf="isAllowOutDepOption" formControlName="is_allow_out_dep">
          {{ 'global.is_allow_out_dep' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </form>

  <!-- <form [formGroup]="manageOfficeFormGroup" *ngIf="isPositionNotOfficer">
    <mat-error @fadeOpacity class="error-label" *ngIf="manageOfficeFormGroup.hasError('required')">{{'user.create_edit_dialog.form.mno.required' | translate}}</mat-error>
    <ng-template #officeList let-list>
      <ng-container *ngFor="let office of list.data">
        <mat-checkbox (change)="test(office)" [value]="office._id" formControlName="manage_office">
          {{ office.name }}
        </mat-checkbox>
        <ng-container *ngIf="office.childs">
          <ng-container
            *ngTemplateOutlet="officeList; context:{ $implicit: { data: office.childs, index: list.index + 1, parent: office, collapse: office.collapse }}">
          </ng-container>
        </ng-container>

      </ng-container>
    </ng-template>

    <ng-container
      *ngTemplateOutlet="officeList; context:{ $implicit: {data: offices, index: 0, parent: null, collapse: true}}">
    </ng-container>
  </form> -->
  <!-- {{ selectedOfficeIDs | json }} {{ selectedManageOfficeID | json }} -->
  <form [formGroup]="manageOfficeFormGroup" *ngIf="isPositionNotOfficer">
    <mat-error @fadeOpacity class="error-label" *ngIf="manageOfficeFormGroup.hasError('required')">{{'user.create_edit_dialog.form.mno.required' | translate}}</mat-error>
    <mat-error @fadeOpacity class="error-label" *ngIf="manageOfficeFormGroup.hasError('invalid')">{{'user.create_edit_dialog.form.mno.invalid' | translate}}</mat-error>
    <mat-label style="margin-bottom: 15px;">{{'user.create_edit_dialog.form.choose_office' | translate}}</mat-label>
    <app-select-office-tree formControlName="manage_office" [offices]="offices"></app-select-office-tree>

    <!-- <ng-template #officeList let-list>
      <ng-container *ngFor="let office of list.data">

        <div *ngIf="list.collapse || isEditing || isAutoCheck(office._id)"
          [style.padding-left]=" 18 * list.index + 'px'" class="tfield color-primary row-office" @fade>
          <button mat-icon-button style="height: 40px; width: 40px;"
            (click)="office.collapse = !!!office.collapse; clickCollapse(office);"
            [style.visibility]="office.childs.length ? 'show': 'hidden'">
            <mat-icon *ngIf="!office.collapse && !isAutoCheck(office._id)">arrow_right</mat-icon>
            <mat-icon *ngIf="office.collapse || isAutoCheck(office._id)">arrow_drop_down</mat-icon>
          </button>
          <!-- OLD CODE CHECK CONDITIONAL selectedOfficeIDs.includes(office._id) || selectedManageOfficeID.includes(office._id)
          <!--  METHOD 2  ; onAutoAddParent(office._id);
          <mat-checkbox [value]="office"
            [checked]="list.index < 1 ? isAllChildSelected(office) : selectedOfficeIDs.includes(office._id) || selectedManageOfficeID.includes(office._id)"
            *ngIf="checkMangeOffice(office._id) && !isSelfEdit"
            [disabled]="preventOfficeIDs.includes(office._id)"
            (change)="onCheckBoxChange($event, list)">
          </mat-checkbox>
          <!-- OLD CODE CHECK CONDITIONAL selectedOfficeIDs.includes(office._id) || selectedManageOfficeID.includes(office._id)
          <!--  METHOD 2  ; onAutoAddParent(office._id);
          <mat-checkbox
            [value]="office"
            [checked]="list.index < 1 ? isAllChildSelected(office) : selectedOfficeIDs.includes(office._id) || selectedManageOfficeID.includes(office._id)"
            *ngIf="isSelfEdit"
            [disabled]="preventOfficeIDs.includes(office._id)"
            (change)="onCheckBoxChange($event, list)">
          </mat-checkbox>

          <div class="choose-office">
            <p>{{office.name}}</p>
          </div>
        </div>

        <ng-container *ngIf="office.childs">
          <ng-container
            *ngTemplateOutlet="officeList; context:{ $implicit: { data: office.childs, index: list.index + 1, parent: office, collapse: office.collapse }}">
          </ng-container>
        </ng-container>

      </ng-container>
    </ng-template>

    <ng-container
      *ngTemplateOutlet="officeList; context:{ $implicit: {data: offices, index: 0, parent: null, collapse: true}}">
    </ng-container> -->
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="{ status: false, avatarId: avatarId }">{{'user.create_edit_dialog.button.cancel' | translate}}</button>
  <ng-container *ngIf="isRequest; else userSubmit">
    <button *ngIf="data.isCreateReq; else editReq" [disabled]="isLoading" (click)="onCreate()" color="accent" mat-raised-button>
      {{'user.create_edit_dialog.button.save' | translate}}
    </button>
    <ng-template #editReq>
      <button [disabled]="isLoading" (click)="onEdit()" color="accent" mat-raised-button>
        {{'user.create_edit_dialog.button.save' | translate}}
      </button>
    </ng-template>
  </ng-container>
  <ng-template #userSubmit>
    <button *ngIf="!user" [disabled]="isLoading" (click)="onCreate()" color="accent" mat-raised-button>
      {{'user.create_edit_dialog.button.save' | translate}}
    </button>
    
    <button *ngIf="user" [disabled]="isLoading" (click)="onEdit()" color="accent" mat-raised-button>
      {{'user.create_edit_dialog.button.save' | translate}}
    </button>
  </ng-template>
</mat-dialog-actions>