import { LocalstorageService } from './../../../core/services/localstorage.service';
import { MessageComponent } from './../../../shared/dialogs/message/message.component';
import { NewAPIService } from "./../../../core/services/new-api.service";
import { Component, OnInit, LOCALE_ID, Inject, OnDestroy } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AuthService } from "../../../core/services/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SearchService } from "../../../core/services/search.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { debounceTime, distinctUntilChanged, filter, takeUntil } from "rxjs/operators";
import { NavigationModel } from "src/app/core/models/navigation.model";
import { NavigationItemModel } from "src/app/core/models/navigation-item.model";
import { ChangePasswordDialogComponent } from "src/app/shared/dialogs/change-password-dialog/change-password-dialog.component";

import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { LocaleService } from "../../../core/services/locale.service";
import { NotificationService } from "src/app/core/services/notification.service";
import { OfficeModel } from "src/app/core/models/office.model";
import { DocumentTypeModel } from "src/app/core/models/document-type.model";
import { CabinetModel } from "src/app/core/models/cabinet.model";
import { combineLatest, Subject, Subscription, timer } from "rxjs";
import { ROLE } from "src/app/core/models/enum/role.enum";
import { NavService } from "src/app/core/services/nav.service";
import { MoreForm } from "src/app/core/models/more-form.model";
import jwt_decode from "jwt-decode";
import { LocalStorageEnum } from 'src/app/core/models/enum/local-storage.enum';
import { UserModel } from 'src/app/core/models/user.model';
import { SERVER_STATUS } from 'src/app/core/models/enum/server_response.enum';
import { DatePipe } from '@angular/common';
import { EXCEL_EXTENSION, EXCEL_TYPE, NewExcelService } from 'src/app/core/services/new-excel.service';
import { MatSelectChange } from '@angular/material/select';
import { NewRequestService } from 'src/app/core/services/new-request.service';
import { Alignment } from 'exceljs';
import { DOC_STATE } from 'src/app/core/models/enum/states.enum';
import { LocaleNumberPipe } from 'src/app/core/pipe/locale-number.pipe';
import * as moment from 'moment';
import { TempChatService } from 'src/app/core/services/temp-chat.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    trigger("search", [
      state("void", style({ opacity: 0, transform: "translateY(-10px)" })),
      transition("void <=> *", animate(".35s ease")),
    ]),
    trigger('fade', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('150ms', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('150ms', style({ height: 0, opacity: 0 }))
      ])
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  isEditing = false;
  handsetBreakpoint = false;
  searchEnabled: boolean = false;
  isShowSearch: boolean = false;
  trackingTotal = 0;
  profileName = "";
  searchControl: FormControl;
  showQuickAction: boolean = true;
  panelOpenState: boolean = false;
  lang: string = '';
  letterIndex: number = 0;
  currentUserRole: string = "";
  allItems: NavigationItemModel[] = [];
  navItems: any;
  state_soft_only: any;

  displayMenuItems: Array<NavigationModel> = [];
  arrayMenuItem: any = { groupTitle: "", items: [] };
  arrayMenuItems: Array<any> = [];
  notifications: Array<any> = [];

  currentPage = 1;
  showMenu: boolean = false;
  states: any[];

  professionalOfficerRoles: ROLE[] = [ROLE.ADMIN, ROLE.APPROVER, ROLE.VERIFIER, ROLE.WRITER];

  offices: OfficeModel[] = [];
  cabinets: CabinetModel[] = new Array<CabinetModel>();
  boxes: CabinetModel[] = [];
  folders: CabinetModel[] = new Array<CabinetModel>();
  doc_types: DocumentTypeModel[] = [];
  accessibilities: MoreForm[] = [];
  formGroup: FormGroup;
  expiredTime: number = 0;
  decode: any;
  token: string;
  user: UserModel;
  timer: any;
  manualUsers: number[] = [1, 3, 4];
  downloadingManual: boolean = false;

  private getBoxesSubscription: Subscription = new Subscription();
  private getFolderSubscription: Subscription = new Subscription();
  private subcription: Subscription;

  private _destroyed = new Subject<void>();

  // treeNavControl = new FlatTreeControl<{expandable: boolean}>(
  //   null, node => node.expandable
  // );
  // private _transformer = (node: any, level: number) => {
  //   return {
  //     expandable: !!node.items && node.items.length > 0,
  //     name: node.items,
  //     level: level,
  //   };
  // }
  // treeFlattener = new MatTreeFlattener(
  //   this._transformer, node => node.level, node => node.expandable, node => node.items);
  private searchSubscription: Subscription;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private request: NewRequestService,
    private auth: AuthService,
    private dialog: MatDialog,
    private ls: LocalstorageService,
    private newApi: NewAPIService,
    private searchService: SearchService,
    public notification: NotificationService,
    @Inject(LOCALE_ID) protected localeId: string,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    public localeService: LocaleService,
    public nav: NavService,
    private newExcel: NewExcelService,
    private datePipe: DatePipe,
    private localeNumber: LocaleNumberPipe,
    private chatService: TempChatService,
  ) {
    this.chatService.initSocket();
    this.user = JSON.parse(this.ls._getDecryption(LocalStorageEnum.user));
    this.currentUserRole = this.user.role._id.toString();
    this.formGroup = formBuilder.group({
      search: [""],
      office: "",
      doc_type: [""],
      cabinet: null,
      box: [""],
      folder: [""],
      is_expired: [false],
      state: [false],
      start_date: [""],
      end_date: [""],
      date: [""]
    });

    this.searchService.form = this.formGroup;

    this.searchService.reset = () => {
      this.boxes = [];
      this.folders = [];
      this.cabinets = [];
      this.formGroup.reset();
    }

    this.translate.onDefaultLangChange.pipe(takeUntil(this._destroyed)).subscribe((event: LangChangeEvent) => {
      event.lang = this.auth.getLocale();
    });

    this.searchService.visibilityObservable().subscribe((x) => {
      this.searchEnabled = x;
      if (x) this.formGroup.get("search").reset();
    });

    this.autoSignOut();

    this.auth.logInEventEmitter.subscribe(res => {
      if (res) {
        this.onDefault();
      }
    });

    this.auth.logOutEventEmitter.subscribe(res => {
      if (res) {
        this.dialog.closeAll();
      }
    });

    this.onDefault();
    this.searchSubscription = this.formGroup.get('search')!.valueChanges
    .pipe(debounceTime(300),  distinctUntilChanged()) 
    .subscribe(val => {
      console.log('ok')
      this.onEmitSearch()
    });
  }

  ngAfterContentChecked() {
    this.isShowSearch = this.searchEnabled;
  }

  ngOnInit(): void {
    this.subcription = this.searchService.loadSavedFilter.subscribe((searchQuery) => {
      searchQuery.is_expired = searchQuery.is_expired == '0' ? false : true;

      if (searchQuery.cabinet) {
        this.getBoxes();
        this.getFolders();
      }

      this.formGroup.patchValue(searchQuery);
    });

    // this.title.setTitle("Archive Management System");
    this.lang = this.auth.getLocale() ? this.auth.getLocale() : "km";
    this.translate.setDefaultLang(this.lang);
    // this.navItems = new MatTreeFlatDataSource(this.treeNavControl, this.treeFlattener);
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.nav.getNavItems();
    // });

    let filter: any = this.ls.get(LocalStorageEnum.filter);
    if (filter) {
      filter = JSON.parse(this.ls.get(LocalStorageEnum.filter));
      this.formGroup.patchValue(filter);
    }

    this.formGroup.get("office").valueChanges.subscribe(officeID => {
      if (!this.professionalOfficerRoles.includes(this.currentUserRole as ROLE)) {
        return;
      }

      this.boxes = [];
      this.folders = [];
      this.cabinets = [];
      this.formGroup.get("cabinet").reset();


      if (!officeID && this.allowGetCabinet) {
        this.newApi.getCabinets().subscribe(response => {
          if (response.status == SERVER_STATUS.SUCCESS)
            this.cabinets = response.cabinets;
        });
        return;
      };

      this.newApi.getCabinetsByOfficeID(officeID).subscribe(response => {
        if (response.status == SERVER_STATUS.SUCCESS)
          this.cabinets = response.cabinets
      })
    });
    // this.formGroup.get("cabinet").valueChanges.subscribe(this.onSelectCabinet.bind(this));
    // this.formGroup.get("box").valueChanges.subscribe(this.onSelectBox.bind(this));
    this.nav.getNavItems();

    if (this.currentUserRole == ROLE.VERIFIER) {
      this.showQuickAction = false;
    }

    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait])
      .subscribe((res) => {
        this.handsetBreakpoint = res.matches;
      });

    this.getNotifications(1);

    this.getUserProfile();

    this.onEmitSearch();
    this.nav.updatePendingCount();
    this.getStates();
  }

  onClearAdvancedSearch() {
    this.boxes = [];
    this.folders = [];
    this.cabinets = [];
    this.ls.delete(LocalStorageEnum.filter);

    this.formGroup.reset();
    this.getDefaultValue();
    this.onEmitSearch();
  }

  switchLanguage(language: string) {
    this.translate.use(language).subscribe(() => { });

    this.lang = language;
    this.localeService.bindLanguage(language);
    this.auth.saveLocale(language);

    if (language == 'en') this.translate.setDefaultLang('en');
    else this.translate.setDefaultLang('km');
  }

  public async signOut() {
    // this.nav.updatePendingCount();

    this.chatService.disconnectSocket();
    await this.auth.signOut();
  }

  getUserProfile() {
    this.newApi.getProfile().subscribe((response) => {
      this.profileName = response.profile.last_name + " " + response.profile.first_name;
    });
  }

  getNotifications(currentPage: number = 1) {
    if (this.currentUserRole != ROLE.ADMIN && this.currentUserRole !== ROLE.APPROVER && this.currentUserRole !== ROLE.VERIFIER && this.currentUserRole !== ROLE.POST_OFFICER) return;
    this.newApi.getNotifications(currentPage).subscribe((res: any) => {
      if (res.status != SERVER_STATUS.SUCCESS) {
        // FAILED GET NOTIFICATION
        return;
      }
      this.notifications.push(...res.arr_notifications);
    });
  }

  openDialogChangePassword() {
    this.dialog
      .open<ChangePasswordDialogComponent>(ChangePasswordDialogComponent, {
        panelClass: "less-shadow",
        disableClose: true,
        width: "400px",
        data: {},
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          //TODO: ...
        }
      });

    // this.dialog.open<ConfirmDialogComponent>(ConfirmDialogComponent, {
    //   width: "450px",
    //   data: {
    //     title: this.translate.instant('pwd_dialog.title'),
    //     info: this.translate.instant('pwd_dialog.info'),
    //     subtitle: this.translate.instant('pwd_dialog.comfirm'),
    //     positiveText: this.translate.instant('pwd_dialog.positive'),
    //     negativeText: this.translate.instant('pwd_dialog.negative')
    //   },
    // }).afterClosed().subscribe((result: any) => {
    //   if(result) {
    //     this.newApi.resetPassword(this.user._id).subscribe((res: any) => {
    //       if(res.status == 1) {
    //         // Do something here!
    //       }
    //     });
    //   }
    // });
  }

  onScrollDown() {
    this.currentPage++;
    this.getNotifications(this.currentPage);
  }

  clickCollapse(object: any) {
    if (!object.collapse) {
      this.traverseCollapse(object);
    }
  }

  traverseCollapse(object: any) {
    object.collapse = true;
    if (object.childs.length) {
      object.childs.forEach((of) => this.traverseCollapse(of));
    }
  }

  downloadManual(): void {
    this.downloadingManual = true;
    const manualFile = "Manual Archiev MS.pdf";
    let blob: Blob;
    const manualPath = "/assets/manual/" + this.user.role.name.toLowerCase() + "/" + manualFile;
    this.request.get(manualPath).subscribe(
      (res: any) => {
        blob = res.body;
      },
      error => {
        this.downloadingManual = false;
        throw new Error(JSON.stringify(error))
      },
      () => {
        this.downloadingManual = false;
        let a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = manualFile;
        a.click();
      }
    )
  }

  openAdvanceSearch(): void {
    combineLatest([/*this.newApi.getOfficesTree(),*/ this.newApi.getAllOfficesTree(), this.newApi.getLetterTypeTree(), this.newApi.getAccessibilities()/*, this.newApi.getCabinets() */])
      .subscribe((response: any[]) => {
        if (response[0].status == SERVER_STATUS.SUCCESS) this.offices = response[0].offices;
        if (response[1].status == SERVER_STATUS.SUCCESS) this.doc_types = response[1].letter_types;
        if (response[2].status == SERVER_STATUS.SUCCESS) this.accessibilities = response[2].accessibilities;

        this.offices.forEach((item: any) => {
          this.traverseCollapse(item);
        });

        this.doc_types.forEach((item: any) => {
          this.traverseCollapse(item);
        });
      })
  }

  preventMatMenu(e) {
    e.stopPropagation();
  }

  private getBoxes(): void {
    this.getBoxesSubscription.unsubscribe();
    const value = this.formGroup.value;
    if (!this.professionalOfficerRoles.includes(this.currentUserRole as ROLE)) {
      return;
    }
    this.getBoxesSubscription = this.newApi.getBoxes({
      cabinet: value.cabinet,
      office: value.office,
      letter_set: this.letterIndex == 0 ? value.doc_type : null,
    }).subscribe(response => {
      if (response.status == SERVER_STATUS.SUCCESS)
        this.boxes = response.boxes;
    });
  }

  private getFolders(): void {
    if (!this.professionalOfficerRoles.includes(this.currentUserRole as ROLE)) {
      return;
    }
    this.getFolderSubscription.unsubscribe();
    const value = this.formGroup.value;
    this.getFolderSubscription = this.newApi
      .getFoldersByParams({
        box: value.box,
        cabinet: value.cabinet,
        office: value.office,
        letter_set: this.letterIndex == 0 ? value.doc_type : null,
        letter_type: this.letterIndex == 1 ? value.doc_type : null
      })
      .subscribe(response => {
        if (response.status == SERVER_STATUS.SUCCESS)
          this.folders = response.folders;
      });
  }

  onLetterChange(e: MatSelectChange): void {
    this.formGroup.get("box").reset();
    this.formGroup.get("folder").reset();
    const selected: any = e.source.selected;
    this.letterIndex = parseInt(selected._element.nativeElement.dataset.letter, 10);
    this.getBoxes();
    // this.getFolders();
  }

  onEmitSearch() {
    let {
      search,
      office,
      doc_type,
      cabinet,
      box,
      folder,
      is_expired,
      state,
      start_date,
      end_date,
      date
    } = this.formGroup.value;

    let filter: any = {
      page: 1,
      search: search ? search : "",
      office: office ? office : "",
      doc_type: doc_type ? doc_type : "",
      cabinet: cabinet ? cabinet : "",
      box: box ? box : "",
      folder: folder ? folder : "",
      is_expired: is_expired && is_expired != '0' ? '1' : '0',
      state: state ? this.state_soft_only?._id : "",
      start_date: this.transformDateApi(start_date),
      end_date: this.transformDateApi(end_date),
      date: this.transformDateApi(date)
    };

    this.ls.set(LocalStorageEnum.filter, JSON.stringify(filter));
    this.searchService.emitQuery(filter);
  }

  onSelectBox(event: any): void {
    this.folders = [];
    this.formGroup.get("folder").reset();
    this.getFolders();
    // let selectedBox = this.boxes.find(box => event.value == box._id);
    // if (selectedBox)
    //   this.folders = selectedBox.childs;
  }

  private getDefaultValue(): void {
    this.formGroup.patchValue({ office: "", doc_type: "", is_expired: false });
  }

  onSelectCabinet(event: any): void {
    this.boxes = [];
    this.folders = [];
    this.formGroup.get("box").reset();
    this.formGroup.get("folder").reset();
    this.getBoxes();
    // let selectedCabinet: any = this.cabinets.find(cabinet => event.value == cabinet._id);
    // if (selectedCabinet)
    //   this.boxes = selectedCabinet.childs;
  }

  onAlertExpiredTokenDialog() {
    var exptime = this.expiredTime * 1000 - Date.now();
    timer(exptime)
      .subscribe(() => {
        this.dialog.open<MessageComponent>(MessageComponent, {
          width: "500px",
          disableClose: true,
          autoFocus: false,
          data: {
            is_expired_token: true
          }
        })
          .afterClosed()
          .subscribe(response => {
            if (response) {
              this.getNewToken();
            } else {
              this.signOut();
            }
          })
      })

    // let now = Date.now();
    // this.timer = setInterval(() => {
    //   now = Date.now() / 1000;
    //   this.token = this.ls.get(LocalStorageEnum.token);

    //   if (this.token && now >= this.expiredTime) {
    //     clearInterval(this.timer);
    //     this.dialog.open<MessageComponent>(MessageComponent, {
    //       width: "500px",
    //       disableClose: true,
    //       autoFocus: false,
    //       data: {
    //         is_expired_token: true
    //       }
    //     })
    //       .afterClosed()
    //       .toPromise()
    //       .then((response) => {
    //         if (response) {
    //           this.getNewToken();
    //         }
    //         else {
    //           this.signOut();
    //         }
    //       })
    //       .catch(error => {
    //         throw new Error(JSON.stringify(error))
    //       })
    //   }
    //   else if (!this.token) {
    //     clearInterval(this.timer);
    //   }
    // }, 1000);
  }

  getNewToken() {
    this.auth.getRefreshToken().subscribe(res => {
      this.onDefault();
    },
      err => console.error(err)
    )
  }

  onDefault() {
    this.token = this.ls.get(LocalStorageEnum.token);
    if (this.token) {
      this.decode = jwt_decode(this.token);
      this.onAlertExpiredTokenDialog();
    }
    if (this.decode) this.expiredTime = this.decode.exp;  // expired early 0s
  }

  autoSignOut() {
    let now = Date.now() / 1000;
    this.token = this.ls.get(LocalStorageEnum.token);
    this.decode = jwt_decode(this.token);
    if (this.decode) this.expiredTime = this.decode.exp;
    if (now > this.expiredTime) {
      this.dialog.closeAll();
      this.signOut();
    }
  }

  toggleMenuByIndex(index: number): void {
    this.nav.displayMenuItems[index].isExpanded = !this.nav.displayMenuItems[index].isExpanded;
  }

  getShallowOffice(): OfficeModel[] {
    const offices = [];
    const each = (office) => {
      office.forEach(office => {
        offices.push(office)
        if (office.childs.length)
          each(office.childs);
      })
    }
    each(this.offices);
    return offices;
  }

  private transformDateApi(date): string {
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }

  exportAdvanceSearchToExcel() {
    let queries = this.formGroup.value;
    let query = {
      search: queries.search ? queries.search : "",
      office: queries.office ? queries.office : "",
      doc_type: queries.doc_type ? queries.doc_type : "",
      cabinet: queries.cabinet ? queries.cabinet : "",
      box: queries.box ? queries.box : "",
      folder: queries.folder ? queries.folder : "",
      is_expired: queries.is_expired ? '1' : '0',
      state: queries.state ? this.state_soft_only?._id : "",
      start: this.transformDateApi(queries.start_date),
      end: this.transformDateApi(queries.end_date),
      date: this.transformDateApi(queries.date)
    };

    this.newApi.getDocumentsByParams(query).subscribe(response => {
      if (response.status != SERVER_STATUS.SUCCESS) return;
      this.newExcel.getTemplate("របាយការណ៍ឯកសារ")
        .then(wb => {
          const ws = wb.getWorksheet(1);
          const A = ws.getColumn("A");
          ws.getRow(4).alignment = { horizontal: "left" };
          ws.getRow(5).alignment = { horizontal: "left" };
          const officeCell = ws.getCell("A5");
          const office = this.getShallowOffice().find(office => office._id == this.formGroup.value.office) || {} as OfficeModel;
          officeCell.value = office.name;
          officeCell.font = { name: "Khmer MEF2", size: 8 };
          const headerRow = ws.getRow(8);
          headerRow.values = ["ល.រ", "ចំណងជើង", "លេខលិខិត", "កាលបរិច្ឆេទ", "លក្ខណៈ", "ប្រភេទលិខិត", "អង្គភាព", "មន្ត្រីបង្កើត", "លេខកូដទូ", "លេខកូដប្រអប់", "លេខកូដស៊ឺមី"];
          ws.unMergeCells(10, 1, 10, headerRow.values.length);
          headerRow.font = { size: 8, name: "Khmer MEF1", bold: true };
          const start: number = 9;
          const font = { size: 8, name: "Khmer MEF1" };
          const COLB = ws.getColumn('B'),
            COLC = ws.getColumn('C'), COLD = ws.getColumn('D'), COLE = ws.getColumn('E'), COLF = ws.getColumn('F'),
            COLG = ws.getColumn('G'), COLH = ws.getColumn("H"), COLI = ws.getColumn('I');
          COLB.width = 23;
          COLC.width = 12;
          COLD.width = 11;
          COLE.width = 13;
          COLF.width = 15;
          COLG.width = 10;
          COLH.width = 15;
          COLI.width = 15;
          response.docs.forEach((doc, i) => {
            let row = ws.getRow(start + i);
            row.font = font;
            row.values = [
              this.localeNumber.transformToKm(i + 1),
              doc.title,
              (this.localeNumber.transformToKm(doc.reference_id) || "គ្មាន"),
              moment(doc.date).locale('km').format("DD/MMM/yyyy"),
              doc.states.map(state => this.states.find(s => s._id == state).name_kh).join(","),
              doc.type.type,
              doc.office.name,
              doc.created_user.last_name + " " + doc.created_user.first_name,
              doc.cabinet ? doc.cabinet.code : 'គ្មាន',
              (Array.from(new Set(doc.folders.map(folder => folder.parent.code))).join(', ') || 'គ្មាន'),
              (Array.from(new Set(doc.folders.map(folder => folder.code))).join(', ') || "គ្មាន")
            ];
          });
          [1, 2, 3, 6].forEach(val => {
            ws.unMergeCells(val, 1, val, headerRow.values.length as number - 1);
            ws.mergeCells(val, 1, val, headerRow.values.length as number - 1);
          });
          // ["B", "E", "F"].forEach((letter, i) => {
          //   const col = ws.getColumn(letter);
          //   col.eachCell((cell, row) => {
          //     if (row > 7 && typeof cell.value == "string" && cell.value.length + 1.5 > col.width)
          //       col.width = cell.value.length + 1.5;
          //   });
          // });
          // ["H", "I"].forEach(letter => {
          //   const col = ws.getColumn(letter);
          //   col.eachCell((cell, row) => {
          //     if (row > 7 && typeof cell.value == "string" && cell.value.length * 1.5 > col.width)
          //       col.width = cell.value.length * 1.5;
          //   });
          // });
          const alignment: Alignment = { vertical: 'middle', horizontal: 'left', wrapText: true } as Alignment;

          ws.eachRow((row, n) => {
            if (n > 7) {
              row.height = 0;
              row.eachCell({ includeEmpty: true }, (cell) => {
                cell.alignment = alignment;
                cell.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" }
                };
              });
            }
          });
          headerRow.alignment = { vertical: 'middle', horizontal: 'left' };
          A.alignment = { horizontal: "center", vertical: 'middle' };
          ws.getRow(4).alignment = { vertical: 'middle' };
          wb.xlsx.writeBuffer()
            .then(buffer => {
              let a = document.createElement("a");
              a.download = "របាយការណ៍ឯកសារ - " + Date.now() + EXCEL_EXTENSION;
              a.href = URL.createObjectURL(new Blob([buffer], { type: EXCEL_TYPE }));
              a.click();
              ws.destroy();
            })
            .catch(console.log)
        })
        .catch(console.log);
      // let excelData = response.docs.map((doc, index) => ({
      //   [this.translate.instant("global.no")]: index + 1,
      //   [this.translate.instant("global.title")]: doc.title,
      //   [this.translate.instant("global.date")]: this.datePipe.transform(doc.date, "dd-MMM-yyyy"),
      //   [this.translate.instant("global.reference_id")]: doc.reference_id ? doc.reference_id : '',
      //   [this.translate.instant("global.letter_type")]: doc.type.type,
      //   [this.translate.instant("global.office")]: doc.office.name,
      //   [this.translate.instant("global.cabinet_code")]: doc.cabinet ? doc.cabinet.code : '',
      //   [this.translate.instant("global.box_code")]: Array.from(new Set(doc.folders.map(folder => folder.parent.code))).join(', '),
      //   [this.translate.instant("global.folder_code")]: Array.from(new Set(doc.folders.map(folder => folder.code))).join(', ')
      // }));
      // this.excel.exportAsExcelFile(excelData, "Advance Filter Document");
    });
  }

  getStates() {
    this.newApi.getStates().subscribe(res => {

      this.states = res.states;
      this.state_soft_only = this.states.find(item => item._id == DOC_STATE.SOFT_ONLY);
    })
  }

  onClickMenu() {
    this.ls.delete(LocalStorageEnum.filter);
    this.ls.delete(LocalStorageEnum.selected_item);
  }

  get allowGetCabinet() {
    let role = this.currentUserRole;

    if (role == ROLE.ADMIN || role == ROLE.APPROVER || role == ROLE.VERIFIER || role == ROLE.WRITER) return true;
    else return false;
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
}

export interface MenuItem {
  title: string; // Title to be displayed
  exact?: boolean; // Highlight when active or not
  routerLink?: string; // Link to redirect when click
  children?: MenuItem[]; // Children of the menu
  roles?: string[]; // Allowed roles for the menu
  icon?: string; // Icon to be displayed
  expanded?: boolean; // Show children or not
}
