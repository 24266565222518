import { Component, OnInit, NgZone, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "../../../core/services/auth.service";
import { OfficeModule } from "src/app/routes/office/office.module";
import { DocumentTypeModule } from "src/app/routes/documents/types/document-type.module";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { LocaleService } from "src/app/core/services/locale.service";
import { SearchService } from "src/app/core/services/search.service";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: 'app-doc-search',
  templateUrl: './doc-search.component.html',
  styleUrls: ['./doc-search.component.scss']
})
export class DocSearchComponent implements OnInit, OnDestroy {
  showForm = true;
  hasSignedIn = false;
  
  fixedHeader: boolean = false;
  hideWhenSignIn: boolean = false;
  txtSearch: string = "";
  currentPage = 1;
  lang: string = '';
  
  showAdvanceSearch: boolean = false;
  offices: OfficeModule[] = [];
  doc_types: DocumentTypeModule[] = [];
  
  searchQuery: any = {
    search: "",
    office: "",
    doc_type: ""
  };

  private _destroyed = new Subject<void>();
  constructor(private router: Router,
    private ngZone: NgZone,
    private translate: TranslateService,
    private localeService: LocaleService,
    private searchService: SearchService,
    private authService: AuthService) {

    router.events.pipe(takeUntil(this._destroyed)).subscribe((val: any) => {
      if (val instanceof NavigationEnd && val.url === "/public/search") {
        this.fixedHeader = false;
      }
    });

    this.translate.onDefaultLangChange.pipe(takeUntil(this._destroyed)).subscribe((event: LangChangeEvent) => {
      event.lang = this.authService.getLocale();
    });
  }

  private _isLoading: boolean;

  public get isLoading(): boolean {
    return this._isLoading;
  }

  ngOnInit() {
    this.lang = this.authService.getLocale();
    this.translate.setDefaultLang(this.authService.getLocale() ? this.authService.getLocale() : 'km');
  }

  doneFadeContent() {
    if (this.hasSignedIn) {
      this.navigate([""]);
    }
  }

  clickCollapse(object: any) {
    if (!object.collapse) {
      this.traverseCollapse(object);
    }
  }

  traverseCollapse(object: any) {
    object.collapse = false;
    if (object.childs.length) {
      object.childs.forEach((of) => this.traverseCollapse(of));
    }
  }

  login() {
    this.hideWhenSignIn = true;
    this.router.navigateByUrl("/sign-in");
  }

  public navigate(commands: Array<string>): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  switchLanguage(language: string) {
    this.translate.use(language).subscribe(() => {
      this.lang = language;
      this.localeService.bindLanguage(language);
    });
  }

  onEmitSearch() {
    this.searchService.emitQuery(this.searchQuery);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
