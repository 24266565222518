<div class="container fixed" id="infinite-scroller" (scroll)="onScroll($event)">
  <div style="height: 5px">
    <mat-progress-bar
      *ngIf="isLoading"
      mode="indeterminate"
      color="accent"
    ></mat-progress-bar>
  </div>

  <div class="content">
    <mat-chip-list class="summary-chip">
      <!-- <mat-chip *ngFor="let summary of requestsSummary | keyvalue" [class]="summary.key">
          {{ 'requests.status.' + summary.key | translate }} : {{ summary.value.total }} {{'notification.chip.request' | translate}}
        </mat-chip> -->
      <ng-container *ngIf="isPending; else determined">
        <mat-chip
          @fadeCenter
          *ngFor="let status of _undeterminedStatuses | keyvalue"
          [class]="status.key"
        >
          {{ "requests.status." + status.key | translate }} :
          {{
            requestsSummary[status.key] ? requestsSummary[status.key].total : 0
          }}
          {{ "notification.chip.request" | translate }}
        </mat-chip>
      </ng-container>
      <ng-template #determined>
        <mat-chip
          @fadeCenter
          *ngFor="let status of _determinedStatuses | keyvalue"
          [class]="status.key"
        >
          {{ "requests.status." + status.key | translate }} :
          {{
            requestsSummary[status.key] ? requestsSummary[status.key].total : 0
          }}
          {{ "notification.chip.request" | translate }}
        </mat-chip>
      </ng-template>
    </mat-chip-list>

    <mat-tab-group
      [selectedIndex]="selectedTab - 1"
      animationDuration="10ms"
      (selectedTabChange)="onTabChange($event)"
    >
      <!-- ============================== សំណើមិនទាន់រួចរាល់ =============================== -->
      <mat-tab>
        <ng-template matTabLabel>
          <span
            style="margin: 5px"
            [matBadge]="
              isShowNumber
                ? nav.pendingBadge == 0
                  ? null
                  : nav.pendingBadge
                : null
            "
            matBadgeColor="warn"
            matBadgeOverlap="false"
          >
            {{ "notification.c_request" | translate }}
          </span>
        </ng-template>

        <div class="tool-bar">
          <!-- Office -->
          <mat-form-field appearance="outline">
            <mat-label>{{ "new_doc.office" | translate }}</mat-label>

            <mat-select
              [formControl]="officeControl"
              [placeholder]="'new_doc.office' | translate"
              (selectionChange)="onFilter()"
            >
              <mat-option value="">{{
                "notification.filter.all" | translate
              }}</mat-option>
              <ng-template #officeList let-list>
                <ng-container *ngFor="let office of list.data">
                  <div
                    *ngIf="list.collapse"
                    [style.padding-left]="18 * list.index + 12 + 'px'"
                    class="tfield color-primary flex"
                  >
                    <button
                      style="float: left; height: 45px; width: 45px"
                      (click)="
                        office.collapse = !!!office.collapse;
                        clickCollapse(office)
                      "
                      [style.visibility]="
                        office.childs.length ? 'show' : 'hidden'
                      "
                      mat-icon-button
                    >
                      <mat-icon *ngIf="!office.collapse">arrow_right</mat-icon>
                      <mat-icon *ngIf="office.collapse"
                        >arrow_drop_down</mat-icon
                      >
                    </button>
                    <mat-option
                      style="width: calc(100% - 45px); float: right"
                      [value]="office._id"
                    >
                      {{ office.name }}
                    </mat-option>
                  </div>

                  <ng-container *ngIf="office.childs">
                    <ng-container
                      *ngTemplateOutlet="
                        officeList;
                        context: {
                          $implicit: {
                            data: office.childs,
                            index: list.index + 1,
                            parent: office,
                            collapse: office.collapse
                          }
                        }
                      "
                    >
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-template>

              <ng-container
                *ngTemplateOutlet="
                  officeList;
                  context: {
                    $implicit: {
                      data: offices,
                      index: 0,
                      parent: null,
                      collapse: true
                    }
                  }
                "
              >
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- Start Date -->
          <mat-form-field appearance="outline">
            <mat-label>{{ "report.start_date" | translate }}</mat-label>
            <input
              matInput
              [formControl]="startDateControl"
              [matDatepicker]="start_picker"
              [placeholder]="'report.start_date' | translate"
              [max]="enddate"
              (dateChange)="onFilter()"
              (focus)="openDatePicker($event, start_picker)"
              readonly
            />
            <button matSuffix mat-icon-button>
              <mat-icon>event</mat-icon>
            </button>
            <!-- <mat-datepicker-toggle matSuffix [for]="start_picker"></mat-datepicker-toggle> -->
            <mat-datepicker #start_picker></mat-datepicker>
          </mat-form-field>

          <!-- End Date -->
          <mat-form-field appearance="outline">
            <mat-label>{{ "report.end_date" | translate }}</mat-label>
            <input
              matInput
              [formControl]="endDateControl"
              [matDatepicker]="end_picker"
              [placeholder]="'report.end_date' | translate"
              [min]="startdate"
              (dateChange)="onFilter()"
              (focus)="openDatePicker($event, end_picker)"
              readonly
            />
            <button matSuffix mat-icon-button>
              <mat-icon>event</mat-icon>
            </button>
            <!-- <mat-datepicker-toggle matSuffix [for]="end_picker"></mat-datepicker-toggle> -->
            <mat-datepicker #end_picker></mat-datepicker>
          </mat-form-field>

          <!-- ជ្រើសរើសប្រភេទ -->
          <mat-form-field class="filter-option" appearance="outline">
            <mat-label>{{ "notification.filter.title" | translate }}</mat-label>
            <mat-select
              [formControl]="statusControl"
              (selectionChange)="onFilter()"
            >
              <!-- <mat-option value="">{{ 'notification.filter.all' | translate }}</mat-option> -->
              <mat-option
                *ngFor="let option of undeterminedStatuses | keyvalue"
                [value]="option.value"
              >
                {{ "requests.status." + option.key | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Button Export Excel -->
          <button
            (click)="exportExcel()"
            class="btn-exp-request"
            mat-stroked-button
            [disabled]="undeterminedRequests.length == 0"
          >
            <mat-icon>calendar_view_month</mat-icon>
            {{ "global.export_as_excel" | translate }}
          </button>
        </div>

        <app-tracking-item
          request="document"
          *ngFor="let item of undeterminedRequests; let i = index"
          [item]="item"
          [index]="i"
          [isYourRequest]="item?.requestor?._id == user_id"
          [isRejected]="item?.status?.status_name === STATUSES.REJECTED"
        ></app-tracking-item>

        <div *ngIf="undeterminedRequestsEmpty" class="empty-box">
          <img src="/assets/images/empty1.svg" alt="" />
          <h3>{{ "empty_list" | translate }}</h3>
        </div>
      </mat-tab>

      <!-- ============================== សំណើរួចរាល់់់់់់់ ====================================== -->
      <mat-tab>
        <ng-template matTabLabel>
          <span
            [matBadge]="
              isShowNumber
                ? nav.verifiedBadge > 0
                  ? nav.verifiedBadge
                  : null
                : null
            "
            matBadgeColor="warn"
            matBadgeOverlap="false"
            >{{ "notification.request" | translate }}
          </span>
        </ng-template>

        <div class="tool-bar">
          <!-- Office -->
          <mat-form-field appearance="outline">
            <mat-label>{{ "new_doc.office" | translate }}</mat-label>

            <mat-select
              [formControl]="officeControl"
              [placeholder]="'new_doc.office' | translate"
              (selectionChange)="onFilter()"
            >
              <mat-option value="">{{
                "notification.filter.all" | translate
              }}</mat-option>

              <ng-template #officeList_done let-list>
                <ng-container *ngFor="let office of list.data">
                  <div
                    *ngIf="list.collapse"
                    [style.padding-left]="18 * list.index + 12 + 'px'"
                    class="tfield color-primary flex"
                  >
                    <button
                      style="float: left; height: 45px; width: 45px"
                      (click)="
                        office.collapse = !!!office.collapse;
                        clickCollapse(office)
                      "
                      [style.visibility]="
                        office.childs.length ? 'show' : 'hidden'
                      "
                      mat-icon-button
                    >
                      <mat-icon *ngIf="!office.collapse">arrow_right</mat-icon>
                      <mat-icon *ngIf="office.collapse"
                        >arrow_drop_down</mat-icon
                      >
                    </button>
                    <mat-option
                      style="width: calc(100% - 45px); float: right"
                      [value]="office._id"
                    >
                      {{ office.name }}
                    </mat-option>
                  </div>

                  <ng-container *ngIf="office.childs">
                    <ng-container
                      *ngTemplateOutlet="
                        officeList_done;
                        context: {
                          $implicit: {
                            data: office.childs,
                            index: list.index + 1,
                            parent: office,
                            collapse: office.collapse
                          }
                        }
                      "
                    >
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-template>

              <ng-container
                *ngTemplateOutlet="
                  officeList_done;
                  context: {
                    $implicit: {
                      data: offices,
                      index: 0,
                      parent: null,
                      collapse: true
                    }
                  }
                "
              >
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- Start Date -->
          <mat-form-field appearance="outline">
            <mat-label>{{ "report.start_date" | translate }}</mat-label>
            <input
              matInput
              [formControl]="startDateControl"
              [matDatepicker]="_start_picker"
              [placeholder]="'report.start_date' | translate"
              [max]="enddate"
              (dateChange)="onFilter()"
              (focus)="openDatePicker($event, _start_picker)"
              readonly
            />
            <button matSuffix mat-icon-button>
              <mat-icon>event</mat-icon>
            </button>
            <!-- <mat-datepicker-toggle matSuffix [for]="_start_picker"></mat-datepicker-toggle> -->
            <mat-datepicker #_start_picker></mat-datepicker>
          </mat-form-field>

          <!-- End Date -->
          <mat-form-field appearance="outline">
            <mat-label>{{ "report.end_date" | translate }}</mat-label>
            <input
              matInput
              [formControl]="endDateControl"
              [matDatepicker]="_end_picker"
              [placeholder]="'report.end_date' | translate"
              [min]="startdate"
              (dateChange)="onFilter()"
              (focus)="openDatePicker($event, _end_picker)"
              readonly
            />
            <button matSuffix mat-icon-button>
              <mat-icon>event</mat-icon>
            </button>
            <!-- <mat-datepicker-toggle matSuffix [for]="_end_picker"></mat-datepicker-toggle> -->
            <mat-datepicker #_end_picker></mat-datepicker>
          </mat-form-field>

          <!-- ជ្រើសរើសប្រភេទ -->
          <mat-form-field class="filter-option" appearance="outline">
            <mat-label>{{ "notification.filter.title" | translate }}</mat-label>
            <mat-select
              [formControl]="statusControl"
              (selectionChange)="onFilter()"
            >
              <!-- <mat-option value="">{{ 'notification.filter.all' | translate }}</mat-option> -->
              <mat-option
                *ngFor="let option of determinedStatuses | keyvalue"
                [value]="option.value"
              >
                {{ "requests.status." + option.key | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Button Export Excel -->
          <button
            (click)="exportExcel()"
            class="btn-exp-request"
            mat-stroked-button
            [disabled]="determinedRequests.length == 0"
          >
            <mat-icon>calendar_view_month</mat-icon>
            {{ "global.export_as_excel" | translate }}
          </button>
        </div>

        <div class="notice">
          <mat-chip-list>
            <mat-chip
              *ngFor="let item of requestsSummary | keyvalue"
              [class]="item.key"
            >
              <span
                >{{ "requests.status." + item.key | translate }} :
                {{ item.value.total }}
                {{ "notification.chip.request" | translate }}</span
              >
            </mat-chip>
          </mat-chip-list>
        </div>

        <app-tracking-item
          request="document"
          *ngFor="let item of determinedRequests; let i = index"
          [item]="item"
          [index]="i"
          [isYourRequest]="item?.requestor?._id == user_id"
          [isRejected]="item?.status?.status_name === STATUSES.REJECTED"
        ></app-tracking-item>

        <div *ngIf="determinedRequestEmpty" class="empty-box">
          <img src="/assets/images/empty1.svg" alt="" />
          <h3>{{ "empty_list" | translate }}</h3>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
