import { LocalstorageService } from './localstorage.service';
import { R } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { ROLE } from '../models/enum/role.enum';
import { REQUEST_TYPE } from '../models/enum/request-type.enum';
import { NavigationModel } from '../models/navigation.model';
import { NewAPIService } from './new-api.service';
import { AuthService } from './auth.service';
import { LocalStorageEnum } from '../models/enum/local-storage.enum';
import { NavigationItemModel } from '../models/navigation-item.model';
import { SERVER_STATUS } from '../models/enum/server_response.enum';

enum STATUSES {
  PENDING = 'Pending',
  UPDATE = 'Update',
  VERIFIED = 'Verify',
  APPROVED = 'approved'
}

@Injectable({
  providedIn: 'root'
})
export class NavService {
  private navItems: NavigationModel[] = [];
  public displayMenuItems: NavigationModel[] = [];
  private arrayMenuItems: any[] = [];
  private arrayMenuItem: any = { groupTitle: "", items: [] };
  private currentUserRole: string = '';
  public pendingBadge: number = 0;
  public verifiedBadge: number = 0;
  public pendingUserBadge: number = 0;
  public verifiedUserBadge: number = 0;
  public deleteDocumentBadge: number = 0;
  // Private Property Start
  private docRequesting: number = 0;

  private documentRequestModel: NavigationItemModel = {
    title: "left_menu.documents.menu_notification",
    icon: "article",
    link: "/requests/document",
    roles: [
      ROLE.ADMIN,
      ROLE.APPROVER,
      ROLE.VERIFIER,
      ROLE.WRITER,
      ROLE.POST_OFFICER
    ],
    badge: this.docRequesting
  }
  // private requestDeleteDocumentModel: NavigationItemModel = {
  //   title: "left_menu.documents.request_delete_doc",
  //   icon: 'auto_delete',
  //   link: '/requests/delete_document',
  //   roles: [
  //     ROLE.ADMIN,
  //     ROLE.APPROVER,
  //     ROLE.VERIFIER,
  //     ROLE.WRITER,
  //     ROLE.POST_OFFICER
  //   ]
  // }
  private userRequestModel: NavigationItemModel = {
    title: "left_menu.documents.menu_user_requests",
    icon: 'people_outline',
    link: '/requests/user',
    roles: [
      ROLE.ADMIN,
      ROLE.APPROVER,
      ROLE.VERIFIER,
      ROLE.WRITER
    ]
  }
  constructor(private translate: TranslateService,
    private newApi: NewAPIService,
    private ls: LocalstorageService) {
  }
  getNavItems() {
    let user = JSON.parse(this.ls._getDecryption(LocalStorageEnum.user));
    if (user) this.currentUserRole = user.role._id.toString();
    this.navItems = [
      {
        groupTitle: "left_menu.dashboard.title",
        isExpanded: true,
        items: [
          {
            title: "left_menu.dashboard.menu_dashboard",
            link: "/summary",
            icon: "dashboard",
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER,
              ROLE.READER,
              ROLE.POST_OFFICER
            ]
          },
        ],
      },
      {
        groupTitle: "left_menu.request_create.title",
        isExpanded: true,
        items: [
          {
            title: "left_menu.documents.title",
            link: "/request-create-documents",
            icon: "post_add",
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER,
              ROLE.POST_OFFICER
            ]
          },
        ],
      },
      {
        groupTitle: "left_menu.mail.title",
        isExpanded: true,
        items: [
          this.documentRequestModel,
          // this.requestDeleteDocumentModel,
          this.userRequestModel
        ],
      },
      {
        groupTitle: "left_menu.documents.title",
        isExpanded: true,
        items: [
          {
            title: "left_menu.documents.menu_internalDoc",
            icon: "folder_open",
            link: "/documents",
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER,
              ROLE.POST_OFFICER,
              ROLE.READER,
              ROLE.OFFICER
            ]
          },
          {
            title: "left_menu.documents.minor_document",
            icon: "folder_open",
            link: "/minor-document",
            roles: [
              ROLE.ADMIN,
              ROLE.POST_OFFICER
            ]
          }
        ],
      },
      {
        groupTitle: "left_menu.system_reconciliation.title", //external_doc.title
        isExpanded: true,
        items: [
          {
            title: "left_menu.system_reconciliation.dept_of_admin_and_finance", //external_doc.admin_doc
            icon: "folder_open",
            link: "/external-documents",
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER,
              // ROLE.POST_OFFICER,
              ROLE.READER,
              ROLE.OFFICER
            ]
          },
          {
            title:"left_menu.system_reconciliation.hr_dept",
            icon:"folder_open",
            link: "/hr-documents",
            roles:[
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER,
              // ROLE.POST_OFFICER
            ]
          },
          {
            title:"left_menu.system_reconciliation.national",
            icon:"folder_open",
            link: "/gdnt-documents",
            roles:[
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER,
              // ROLE.POST_OFFICER
            ]
          }
        ],
      },
      {
        groupTitle:"Chat",
        isExpanded:true,
        items:[
          {
            title:"Chat",
            icon:"chat_bubble_outline",
            link:"/chat",
            roles:[
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER,
              ROLE.POST_OFFICER
            ]
          }
        ]
      },
      // {
      //   groupTitle:"left_menu.system_reconciliation.title",
      //   isExpanded:true,
      //   items:[
      //     {
      //       title:"left_menu.system_reconciliation.dept_of_admin_and_finance",
      //       icon:"account_balance",
      //       link:"system_reconciliation/dept_of_admin_and_finance",
      //       roles:[
      //         ROLE.ADMIN,
      //         ROLE.APPROVER,
      //         ROLE.VERIFIER,
      //         ROLE.WRITER,
      //         ROLE.POST_OFFICER
      //       ]
      //     },
      //     {
      //       title:"left_menu.system_reconciliation.hr_dept",
      //       icon:"diversity_3",
      //       link:"system_reconciliation/hr_dept",
      //       roles:[
      //         ROLE.ADMIN,
      //         ROLE.APPROVER,
      //         ROLE.VERIFIER,
      //         ROLE.WRITER,
      //         ROLE.POST_OFFICER
      //       ]
      //     }
      //   ]
      // },
      {
        groupTitle: "left_menu.offices.title",
        isExpanded: false,
        items: [
          {
            title: "left_menu.offices.menu_office",
            link: "/offices",
            icon: "store_mall_directory",
            roles: [
              ROLE.ADMIN
            ]
          },
          {
            title: "left_menu.offices.menu_letter_set",
            link: "/letter/set",
            icon: "dynamic_feed",
            roles: [
              ROLE.ADMIN
            ]
          },
          {
            title: "left_menu.documents.menu_docType",
            icon: "vertical_split",
            link: "/letter/type",
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER,
              ROLE.POST_OFFICER
            ]
          },
          {
            title: "left_menu.offices.menu_cabinet",
            link: "/cabinets",
            icon: "dns",
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER
            ]
          },
          {
            title: "left_menu.offices.menu_box",
            link: "/boxes",
            icon: "all_inbox",
            roles: [
              ROLE.ADMIN,
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER
            ]
          },
          {
            title: "left_menu.offices.folder",
            link: "/folders",
            icon: "folder_open",
            roles: [
              ROLE.ADMIN,
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER
            ]
          }
        ],
      },
      {
        groupTitle: "left_menu.reports.title",
        isExpanded: false,
        items: [
          {
            title: "left_menu.reports.menu_report",
            link: "/reports",
            icon: "insert_drive_file",
            isNotExact: true,
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER,
              ROLE.POST_OFFICER
            ]
          },
          {
            title: "left_menu.reports.expired_report",
            link: "/expired_doc",
            icon: "report",
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER
            ]
          },
          {
            title: "left_menu.reports.letter_set_report",
            link: "reports/letter_set",
            icon: "stacked_bar_chart",
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER
            ]
          },
          {
            title: "left_menu.reports.document_office",
            link: "reports/document_office",
            icon: "apartment",
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER
            ]
          },
          // {
          //   title: "left_menu.reports.document_office",
          //   link: "reports/document_office",
          //   icon: "apartment",
          //   roles: [
          //     ROLE.ADMIN,
          //     ROLE.APPROVER,
          //     ROLE.VERIFIER,
          //     ROLE.WRITER,
          //     ROLE.POST_OFFICER
          //   ]
          // },
          {
            title: "left_menu.reports.download_report",
            link: "reports/document_download",
            icon: "download_for_offline",
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER
            ]
          }
        ],
      },
      {
        groupTitle: "left_menu.users.title",
        isExpanded: true,
        items: [
          {
            title: "left_menu.users.menu_management",
            link: "/users/management",
            icon: "group",
            roles: [
              ROLE.ADMIN,
              ROLE.APPROVER,
              ROLE.VERIFIER,
              ROLE.WRITER
            ]
          },
          {
            title: "left_menu.users.ual",
            link: "/users/log",
            icon: "format_list_bulleted",
            roles: [
              ROLE.ADMIN
            ]
          }
        ],
      },
    ];
    this.displayMenuItems = this.traverseMenuItems(this.navItems);
    this.updatePendingCount();
  }
  private traverseMenuItems(navItems: NavigationModel[]) {
    let hasItem = true;
    this.arrayMenuItems = [];
    navItems.forEach((nav, index) => {
      this.arrayMenuItem = { groupTitle: "", items: [], isExpanded: false, };
      this.arrayMenuItem.groupTitle = nav.groupTitle;
      this.arrayMenuItem.isExpanded = nav.isExpanded;
      nav.items.forEach((item) => {
        if (
          item.roles &&
          this.currentUserRole &&
          item.roles.includes(this.currentUserRole)
        ) {
          this.arrayMenuItem.items.push(item);
          hasItem = false;
        }
      });

      if (!hasItem) {
        this.arrayMenuItems.push(this.arrayMenuItem);
      }
      hasItem = true;
    });
    return this.arrayMenuItems;
  }
  clearPendingCount(): void {
    
  }
  updatePendingCount() {
    if (![ROLE.ADMIN, ROLE.APPROVER, ROLE.VERIFIER].includes(this.currentUserRole as ROLE)) {
      this.pendingBadge = 0;
      this.verifiedBadge = 0;
      this.documentRequestModel.badge = 0;
      this.userRequestModel.badge = 0;
      // this.requestDeleteDocumentModel.badge = 0;
      return;
    }
    /*
    if (!(this.currentUserRole == '1' || this.currentUserRole == '2' || this.currentUserRole == '3')) {
      this.pendingBadge = 0;
      this.verifiedBadge = 0;
      return;
    }
    */
    //  this.newApi.getRequestsStatus().subscribe(response => {
    //     const pending = response.statuses.find(status => status.status_name == STATUSES.PENDING);
    //     const verified = response.statuses.find(status => status.status_name == STATUSES.VERIFIED);
    //     combineLatest([this.newApi.getRequestsByPage(1, 99, [pending._id, verified._id])]).subscribe(responses => {
    //       if (this.currentUserRole == '1' || this.currentUserRole == '2' || this.currentUserRole == '3') {
    //         var notification = this.displayMenuItems.find(nav => {
    //           return nav.items?.find(item => item.link == '/documents/notification');
    //         }).items.find(item => item.link == '/documents/notification');
    //         notification.badge = responses[0].requests.length;
    //         this.pendingBadge = responses[0].requests.filter(req => req.status.status_name === STATUSES.PENDING).length;
    //         this.verifiedBadge = responses[0].requests.filter(req => req.status.status_name === STATUSES.VERIFIED).length;
    //       }
    //       console.log(responses[0].requests.filter(req => req.status.status_name === STATUSES.VERIFIED), 'from nav service')
    //     })
    // })

    combineLatest([this.newApi.getBadge('document'), this.newApi.getBadge('user')]).subscribe((responses: any) => {
       if (responses[0].status == SERVER_STATUS.SUCCESS) {
         this.documentRequestModel.badge = responses[0].summary.map(summary => summary.total).reduce((a, b) => a + b);
         this.pendingBadge = responses[0].summary.find(summary => summary.type == REQUEST_TYPE.UPDATE).total;
        //  this.verifiedBadge = responses[0].summary.find(summary => summary.type == REQUEST_TYPE.VERYFY).total;
       }
        if (responses[1].status == SERVER_STATUS.SUCCESS) {
          this.userRequestModel.badge = responses[1].summary.map(summ => summ.total).reduce((a, b) => a + b);
          this.pendingUserBadge = responses[1].summary.find(summary => summary.type == REQUEST_TYPE.UPDATE).total;
        }
    })
  }
}
