<a
  [routerLink]="['/requests', request, 'detail', item.onModel, item._id]"
  (click)="onSelectItem(index)"
>
  <div
    class="tracking-item masonry-item mat-elevation-z1"
    id="item_{{ index }}"
    [ngStyle]="{
      background:
        isRejected && isYourRequest && item?.reference?.is_editable
          ? '#e5f8ff'
          : '#fff'
    }"
  >
    <div class="set-nav-link">
      <header>
        <div class="col left-content">
          <h3 class="title-doc">
            {{
              isUser
                ? reference?.last_name + " " + reference?.first_name
                : reference?.title
            }}
          </h3>
        </div>
      </header>
    </div>
    <div class="actions">
      <div class="document-info">
        <h5 class="new-code grow" *ngIf="!isUser">
          <span *ngIf="reference?.qr">{{ reference?.qr }}</span>
          <span
            [ngStyle]="
              reference?.qr ? { margin: '0 18px' } : { margin: '0 18px 0 0' }
            "
            >{{
              translateDate(
                item?.final_date | date : "dd-MMMM-yyyy hh:mm a",
                "-"
              )
            }}</span
          >
          <span *ngIf="reference?.office?.parent"
            >{{ reference?.office?.parent?.name }} - </span
          ><span>{{ reference?.office?.name }}</span>
        </h5>

        <h5 class="new-code grow" *ngIf="isUser">
          <span>{{ reference?.office?.name }}</span>
          <span class="spacer">{{
            lang != "en"
              ? reference?.position?.name_kh
              : reference?.position?.name
          }}</span>
          <span>{{ reference?.email }}</span>
          <span class="spacer">{{
            translateDate(item?.updatedAt | date : "dd-MMMM-yyyy hh:mm a", "-")
          }}</span>
        </h5>

        <mat-chip-list class="white-text" *ngIf="item.type">
          <ng-container *ngIf="item.type.name == requestTypes.DELETE">
            <mat-chip class="reject-chip">
              {{ "requests.type.delete" | translate }}
            </mat-chip>
            <mat-chip
              class="verified"
              *ngIf="item.status.status_name == STATUSE_TYPE.PENDING"
            >
              {{ "requests.status.verified" | translate }}
            </mat-chip>
          </ng-container>
          <mat-chip
            *ngIf="item.type.name == requestTypes.CREATE"
            class="create"
            [ngClass]="{ edited: item.previous_requests?.length }"
          >
            {{
              (item.previous_requests?.length
                ? "requests.type.edited"
                : "requests.type.create"
              ) | translate
            }}
          </mat-chip>

          <mat-chip
            *ngIf="item.type.name == requestTypes.UPDATE"
            class="update"
          >
            {{ "requests.type.verify" | translate }}
          </mat-chip>

          <mat-chip *ngIf="item.type.name == requestTypes.VERYFY" class="edit">
            {{ "requests.type.update" | translate }}
          </mat-chip>
          <mat-chip
            *ngIf="item.type.name == requestTypes.RESET_PASSWORD"
            class="reset-password"
          >
            {{ "requests.type.reset_password" | translate }}
          </mat-chip>

          <mat-chip
            *ngIf="item.status.status_name === STATUSE_TYPE.APPROVED"
            class="approve-chip"
          >
            {{ "requests.status.approved" | translate }}
          </mat-chip>

          <mat-chip
            *ngIf="item.status.status_name === STATUSE_TYPE.REJECTED"
            class="reject-chip"
          >
            {{ "requests.status.rejected" | translate }}
          </mat-chip>
          <ng-container *ngIf="item.status.status_name == STATUSE_TYPE.PENDING">
            <!-- IF PENDING AND OTHER -->
            <mat-chip
              *ngIf="item.type.name == requestTypes.UPDATE"
              class="verified"
            >
              {{ "requests.status.requested_for_editing" | translate }}
            </mat-chip>

            <mat-chip
              *ngIf="item.type.name == requestTypes.VERYFY"
              class="pending"
            >
              {{ "requests.status.pending" | translate }}
            </mat-chip>

            <mat-chip
              *ngIf="item.type.name == requestTypes.CREATE"
              class="pending"
            >
              {{ "requests.status.pending" | translate }}
            </mat-chip>
          </ng-container>

          <mat-chip
            *ngIf="
              item.status.status_name == STATUSE_TYPE.VERIFIED ||
              (item.type.name == requestTypes.RESET_PASSWORD &&
                item.status.status_name != STATUSE_TYPE.APPROVED &&
                item.status.status_name != STATUSE_TYPE.REJECTED)
            "
            class="verified"
          >
            {{ "requests.status.verified" | translate }}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
</a>
