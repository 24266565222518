import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnDestroy {

  lang: string = '';

  private _destroyed = new Subject<void>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService) {

    translate.onDefaultLangChange.pipe(takeUntil(this._destroyed)).subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
