import { animate, style, transition, trigger } from "@angular/animations";
import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Font, Alignment } from "exceljs";
import * as moment from "moment";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { Subject, Subscription, takeUntil } from "rxjs";
import { LocalStorageEnum } from "src/app/core/models/enum/local-storage.enum";
import { RequestModel } from "src/app/core/models/enum/request-model";
import { REQ_TAB } from "src/app/core/models/enum/request-tab.enum";
import { STATUSES } from "src/app/core/models/enum/request_status.enum";
import { ROLE } from "src/app/core/models/enum/role.enum";
import { SERVER_STATUS } from "src/app/core/models/enum/server_response.enum";
import { OfficeModel } from "src/app/core/models/office.model";
import { LocaleNumberPipe } from "src/app/core/pipe/locale-number.pipe";
import { LocalstorageService } from "src/app/core/services/localstorage.service";
import { NavService } from "src/app/core/services/nav.service";
import { NewAPIService } from "src/app/core/services/new-api.service";
import {
  NewExcelService,
  EXCEL_EXTENSION,
  EXCEL_TYPE,
} from "src/app/core/services/new-excel.service";
import { SearchService } from "src/app/core/services/search.service";
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from "src/app/routes/reports/components/report/report.component";

interface Summary {
  total: number;
  status_name: STATUSES;
}

interface SummaryModel {
  [key: string]: Summary;
}

@Component({
  selector: "app-document-request",
  templateUrl: "./document-request.component.html",
  styleUrls: ["./document-request.component.scss"],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
  animations: [
    trigger("fadeCenter", [
      transition(":enter", [
        style({ width: 0, whiteSpace: "nowrap", overflow: "hidden" }),
        animate("200ms ease-in", style({ width: "*" })),
      ]),
    ]),
  ],
})
export class DocumentRequestComponent implements OnInit, OnDestroy {
  selectedTab: number = 0;
  requests: any[] = [];
  isLoading: boolean = true;
  isPending = true;
  optionStatuses: any = {};
  readonly STATUSES = STATUSES;
  undeterminedRequests: Array<any> = [];
  undeterminedReports: Array<any> = [];
  verifyRequest: any[] = [];
  determinedRequests: any[] = [];
  isShowOptionFilter: boolean = false;
  currentPage: number = 1;
  limit: number = 10;
  options = {
    transitionDuration: ".1s",
  };
  requestTypes: any = {};
  array = [];
  sum = 100;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  modalOpen = false;
  current_user_role: string;
  commonStatuses: Array<string> = [];
  commonRequestTypes: Array<string> = [];
  isShowNumber: boolean = false;
  date = new Date();
  routerSubscription: Subscription = new Subscription();
  subscription: Subscription = new Subscription();

  undeterminedRequestsEmpty: boolean = false;
  determinedRequestEmpty: boolean = false;

  requestsSummary: SummaryModel = {};

  private getOfficeSubscription: Subscription = new Subscription();
  offices: Array<OfficeModel> = [];

  @Input() reload: boolean;

  officeControl = new FormControl("");
  startDateControl = new FormControl("");
  endDateControl = new FormControl("");
  statusControl = new FormControl("");

  summaryRequestStatus: Array<any> = [];
  scrollEnable = true;

  private _destroyed = new Subject<void>();
  user_id: string = "";
  constructor(
    private datePipe: DatePipe,
    private newApi: NewAPIService,
    private infinitScroll: InfiniteScrollDirective,
    private searchView: SearchService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalstorageService,
    public nav: NavService,
    private title: Title,
    private newExcel: NewExcelService,
    private localeNumber: LocaleNumberPipe
  ) {
    this.title.setTitle("Document Requests");
    let user = JSON.parse(this.ls._getDecryption(LocalStorageEnum.user));
    this.current_user_role = user.role._id.toString();
    this.user_id = user._id;
    this.searchView.setVisibility(false);
  }

  ngOnInit() {
    this.getOfficeTree();
    this.getRequestStatuses();
    this.nav.updatePendingCount();

    this.getSummaryRequest(REQ_TAB.UNDETERMINED);
    this.getSummaryRequest(REQ_TAB.DETERMINED);

    this.routerSubscription = this.route.paramMap.subscribe((route) => {
      this.selectedTab = parseInt(route.get("tab")) || 1;
      this.isPending = this.selectedTab == 1;

      let filter: any = this.ls.get(LocalStorageEnum.filter);
      if (filter) {
        filter = JSON.parse(filter);

        this.limit = filter.page * 10;
        this.officeControl.setValue(filter.office);
        this.startDateControl.setValue(filter.start_date);
        this.endDateControl.setValue(filter.end_date);
        this.statusControl.setValue(filter.status);
      }

      this.init();
    });

    this.isShowNumber = [ROLE.ADMIN, ROLE.APPROVER, ROLE.VERIFIER].includes(
      this.current_user_role as ROLE
    );
  }

  getRequestStatuses(): void {
    this.newApi
      .getRequestsStatus()
      .pipe(takeUntil(this._destroyed))
      .subscribe({
        next: (response) => {
          if (response.status != SERVER_STATUS.SUCCESS) {
            // ERROR
            return;
          }
          response.statuses.forEach(
            (status) => (this.optionStatuses[status.status_name] = status._id)
          );
        },
        complete: () => {},
      });
  }

  onFilter(): void {
    this.currentPage = 1;
    this.determinedRequests = [];
    this.undeterminedRequests = [];

    this.onSaveFilter();
    this.init();
  }

  openDatePicker(event: Event, picker: MatDatepicker<Date>) {
    const target = event.target as HTMLInputElement;
    target.blur();
    picker.open();
  }

  init(): void {
    if (this.isPending) {
      this.getUndeterminedRequests();
    } else {
      this.getDeterminedRequests();
    }
  }

  get undeterminedStatuses() {
    const newObj = Object.assign({ all: "" }, this.optionStatuses);
    delete newObj.rejected;
    delete newObj.approved;
    return newObj;
  }

  get _undeterminedStatuses(): { [key: string]: string } {
    const newObj = Object.assign({}, this.optionStatuses);
    delete newObj.rejected;
    delete newObj.approved;
    return newObj;
  }

  get determinedStatuses() {
    const newObj = Object.assign({ all: "" }, this.optionStatuses);
    delete newObj.pending;
    if (this.current_user_role != ROLE.VERIFIER) delete newObj.verified;
    return newObj;
  }

  get _determinedStatuses(): { [key: string]: string } {
    const newObj = Object.assign({}, this.optionStatuses);
    delete newObj.pending;
    if (this.current_user_role != ROLE.VERIFIER) delete newObj.verified;
    return newObj;
  }

  ngAfterContentChecked() {
    this.isLoading = this.isLoading;
  }

  onTabChange(event) {
    this.infinitScroll.immediateCheck = true;
    this.undeterminedRequests = [];
    this.determinedRequests = [];
    this.requestsSummary = {};
    this.currentPage = 1;
    this.statusControl.setValue("");
    this.officeControl.setValue("");
    this.startDateControl.setValue("");
    this.endDateControl.setValue("");
    this.nav.updatePendingCount();
    this.router.navigate(["/requests/document", event.index + 1]);

    this.ls.delete(LocalStorageEnum.filter);
    this.ls.delete(LocalStorageEnum.selected_item);

    this.getSummaryRequest(REQ_TAB.UNDETERMINED);
    this.getSummaryRequest(REQ_TAB.DETERMINED);
  }

  getUndeterminedRequestsReport(status: string[], type: string[]) {
    this.isLoading = true;
    return this.newApi
      .getRequestsReport(status, "document", type)
      .subscribe((res: any) => {
        this.isLoading = false;
        if (res.status == SERVER_STATUS.SUCCESS) {
          this.undeterminedReports = res.requests;
        }
      });
  }

  getUndeterminedRequests(): void {
    this.subscription.unsubscribe();
    this.isLoading = true;

    this.subscription = this.newApi
      .getRequestsByPage({
        is_determined: REQ_TAB.UNDETERMINED,
        page: this.currentPage,
        count: this.limit,
        onModel: RequestModel.Document,
        start_date: this.datePipe.transform(
          this.startDateControl.value,
          "yyyy-MM-dd"
        ),
        end_date: this.datePipe.transform(
          this.endDateControl.value,
          "yyyy-MM-dd"
        ),
        status: this.statusControl.value || null,
        office: this.officeControl.value || null,
      })
      .subscribe({
        next: (response) => {
          if (response.status != SERVER_STATUS.SUCCESS) {
            // ERROR
            return;
          }
          this.scrollEnable = response.requests.length == this.limit;
          this.undeterminedRequests.push(...response.requests);
          this.undeterminedRequestsEmpty =
            this.undeterminedRequests.length == 0;
          this.currentPage =
            this.limit > 10 ? this.limit / 10 : this.currentPage;

          let selected_item: any = this.ls.get(LocalStorageEnum.selected_item);
          if (selected_item && this.limit > 10) {
            setTimeout(() => {
              document
                .getElementById(`item_${selected_item}`)
                .scrollIntoView({ behavior: "smooth", block: "center" });
              this.limit = 10;
            }, 500);
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  getDeterminedRequests(): void {
    this.subscription.unsubscribe();
    this.isLoading = true;

    this.subscription = this.newApi
      .getRequestsByPage({
        is_determined: REQ_TAB.DETERMINED,
        page: this.currentPage,
        count: this.limit,
        onModel: RequestModel.Document,
        start_date: this.datePipe.transform(
          this.startDateControl.value,
          "yyyy-MM-dd"
        ),
        end_date: this.datePipe.transform(
          this.endDateControl.value,
          "yyyy-MM-dd"
        ),
        status: this.statusControl.value || null,
        office: this.officeControl.value || null,
      })
      .subscribe({
        next: (response) => {
          if (response.status != SERVER_STATUS.SUCCESS) {
            // ERROR
            return;
          }
          this.scrollEnable = response.requests.length == this.limit;
          this.determinedRequests.push(...response.requests);
          this.determinedRequestEmpty = this.determinedRequests.length == 0;
          this.currentPage =
            this.limit > 10 ? this.limit / 10 : this.currentPage;

          let selected_item: any = this.ls.get(LocalStorageEnum.selected_item);
          if (selected_item && this.limit > 10) {
            setTimeout(() => {
              document
                .getElementById(`item_${selected_item}`)
                .scrollIntoView({ behavior: "smooth", block: "center" });
              this.limit = 10;
            }, 500);
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  goToDetailPage(id: string, status: string, reqID: string) {
    this.router.navigateByUrl(`/documents/detail/${id}/${reqID}/${status}`);
  }

  ngOnDestroy(): void {
    this.searchView.setVisibility(false);
    this.routerSubscription.unsubscribe();
    this.getOfficeSubscription.unsubscribe();
    this._destroyed.next();
    this._destroyed.complete();
  }

  exportExcel(): void {
    this.isLoading = true;
    this.newApi
      .getRequestsByPage({
        is_determined: this.isPending
          ? REQ_TAB.UNDETERMINED
          : REQ_TAB.DETERMINED,
        onModel: RequestModel.Document,
        start_date: this.datePipe.transform(
          this.startDateControl.value,
          "yyyy-MM-dd"
        ),
        end_date: this.datePipe.transform(
          this.endDateControl.value,
          "yyyy-MM-dd"
        ),
        status: this.statusControl.value || null,
        office: this.officeControl.value || null,
      })
      .subscribe((response) => {
        if (response.status != SERVER_STATUS.SUCCESS) return;
        let title = this.isPending
          ? "របាយការណ៍សំណើមិនទាន់រួចរាល់"
          : "របាយការណ៍សំណើរួចរាល់";
        this.newExcel
          .getUndeterminedRequestTemplate(title)
          .then((wb) => {
            this.isLoading = false;
            const ws = wb.getWorksheet(1);
            ws.pageSetup.orientation = "portrait";
            const headerRow = ws.getRow(9);
            headerRow.values = [
              "ល.រ",
              "ចំណងជើង",
              "លេខលិខិត",
              "កាលបរិច្ឆេទឯកសារ",
              "លក្ខណៈ",
              "អង្គភាព",
              "អ្នកស្នើសុំ",
              "កាលបរិច្ឆេទស្នើសុំ",
            ];
            headerRow.font = { size: 8, name: "Khmer MEF1", bold: true };
            const dateRow = ws.getRow(7);
            dateRow.values = [moment().locale("km").format("DD/MMM/yyyy")];
            dateRow.font = headerRow.font;
            const start: number = 10;
            const font: Partial<Font> = { name: "Khmer MEF1", size: 8 };

            response.requests.forEach((item, index) => {
              const row = ws.getRow(start + index);
              row.font = font;
              row.values = [
                this.localeNumber.transformToKm(index + 1),
                item.reference.title,
                this.localeNumber.transformToKm(item.reference.reference_id) ||
                  "គ្មាន",
                moment(item.reference.date).locale("km").format("DD/MMM/yyyy"),
                item.reference.states.map((state) => state.name_kh).join(","),
                item.reference.office.name,
                item.requestor.last_name + " " + item.requestor.first_name,
                moment(item.createdAt).locale("km").format("DD/MMM/yyyy"),
              ];
            });

            const C = ws.getColumn("C");
            const B = ws.getColumn("B");
            const D = ws.getColumn("D");
            const E = ws.getColumn("E");
            ws.getCell("A4").alignment = {
              horizontal: "left",
              vertical: "middle",
            };

            [1, 2, 3, 6, 7].forEach((val) => {
              ws.unMergeCells(
                val,
                1,
                val,
                (headerRow.values.length as number) - 1
              );
              ws.mergeCells(
                val,
                1,
                val,
                (headerRow.values.length as number) - 1
              );
            });

            ws.eachRow((row, n) => {
              if (n > 8) {
                row.height = 0;
                row.eachCell((cell) => {
                  cell.alignment = { wrapText: true, vertical: "middle" };
                  cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                  };
                });
              }
            });
            const align: Alignment = {
              horizontal: "center",
              vertical: "middle",
            } as Alignment;
            ws.getColumn("A").alignment = align;
            ws.getRow(4).alignment = { horizontal: "left", vertical: "middle" };
            headerRow.height = 20;
            B.width = 30;
            C.width = 25;
            D.width = 14;
            E.width = 14;
            D.alignment = align;
            E.alignment = align;
            wb.xlsx
              .writeBuffer()
              .then((buffer) => {
                let a = document.createElement("a");
                a.download = title + "-" + Date.now() + EXCEL_EXTENSION;
                a.href = URL.createObjectURL(
                  new Blob([buffer], { type: EXCEL_TYPE })
                );
                a.click();
                ws.destroy();
              })
              .catch(console.log);
          })
          .catch(console.log);
      });
  }

  getOfficeTree() {
    this.getOfficeSubscription.unsubscribe();
    this.isLoading = true;
    this.getOfficeSubscription = this.newApi
      .getOfficesTree()
      .subscribe((response) => {
        this.offices = [];
        this.isLoading = false;
        if (response.status == SERVER_STATUS.SUCCESS) {
          response.offices.forEach((office) => {
            this.offices.push(...office.childs);
          });
        }
      });
  }

  clickCollapse(office: OfficeModel) {
    if (!office.collapse) {
      this.traverseCollapse(office);
    }
  }

  traverseCollapse(office: OfficeModel) {
    office.collapse = false;
    if (office.childs.length) {
      office.childs.forEach((of) => this.traverseCollapse(of));
    }
  }

  getSummaryRequest(tab: REQ_TAB) {
    this.newApi
      .getSummarRequestStatus({
        onModel: RequestModel.Document,
        is_determined: tab,
      })
      .subscribe((res) => {
        if (res.status == SERVER_STATUS.SUCCESS) {
          res.summaryRequestStatus.forEach((summary: Summary) => {
            this.requestsSummary[summary.status_name] = summary;
          });
        }
      });
  }

  get dataJson() {
    const datePipe = new DatePipe("en-US");

    let data = {
      page: this.currentPage.toString(10),
      count: this.limit.toString(10),
      status: this.commonStatuses,
      onModel: "document",
      type: this.commonRequestTypes,
      start_date: this.startDateControl.value
        ? datePipe.transform(this.startDateControl.value, "yyyy-MM-dd")
        : undefined,
      end_date: this.endDateControl.value
        ? datePipe.transform(this.endDateControl.value, "yyyy-MM-dd")
        : undefined,
      office: this.officeControl.value._id,
    };

    return data;
  }

  onScroll(event) {
    let offsetHeight = event.target.offsetHeight; // visible div height.
    let scrollTop = event.target.scrollTop; // the length that count from bottom of the visible div height to the top.
    let scrollHeight = event.target.scrollHeight; // overall height of the div.

    if (this.scrollEnable) {
      // this is where scroll reach the bottom.
      // in this case, I set it to if the scroll reach 80% of the overall height, get new data.
      if (scrollTop > 0 && offsetHeight + scrollTop >= scrollHeight * 0.8) {
        this.scrollEnable = false;

        this.currentPage++;

        this.onSaveFilter();
        this.init();
      }
    }
  }

  onSaveFilter() {
    const datePipe = new DatePipe("en-US");
    let filter_data: any = {
      page: this.currentPage,
      office: this.officeControl.value,
      start_date: this.startDateControl.value
        ? datePipe.transform(this.startDateControl.value, "yyyy-MM-dd")
        : undefined,
      end_date: this.endDateControl.value
        ? datePipe.transform(this.endDateControl.value, "yyyy-MM-dd")
        : undefined,
      status: this.statusControl.value,
    };

    this.ls.set(LocalStorageEnum.filter, JSON.stringify(filter_data));
  }

  get startdate(): Date | null {
    if (this.startDateControl.value)
      return new Date(this.startDateControl.value);
    return null;
  }

  get enddate(): Date | null {
    if (this.endDateControl.value) return new Date(this.endDateControl.value);
    return null;
  }
}
