<div class="container fixed" id="infinite-scroller">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>

  <div class="sub-container report-list mat-elevation-z1">
    <header class="m-l-10">
      <div class="group">
        <h3>{{'report.title' | translate}}</h3>
        <div class="spacer"></div>
        <button class="m-r-10" (click)="exportExcel()" mat-stroked-button [disabled]="isEmpty">
          <mat-icon>calendar_view_month</mat-icon>
          {{ 'global.export_as_excel' | translate }}
        </button>
        <button (click)="exportReports()" mat-stroked-button [disabled]="isEmpty">
          <mat-icon>print</mat-icon>
          {{'report.print' | translate}}
        </button>
      </div>
    </header>

    <div class="group-field">
      <form [formGroup]="formGroup">
        <p>
          <mat-form-field appearance="outline">
            <mat-label>{{'report.start_date' | translate}}</mat-label>
            <input matInput formControlName="start_date" [matDatepicker]="start_picker" [placeholder]="'report.start_date' | translate">
            <mat-datepicker-toggle matSuffix [for]="start_picker"></mat-datepicker-toggle>
            <mat-datepicker #start_picker></mat-datepicker>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline">
            <mat-label>{{'report.end_date' | translate}}</mat-label>
            <input matInput formControlName="end_date" [matDatepicker]="end_picker" [placeholder]="'report.end_date' | translate">
            <mat-datepicker-toggle matSuffix [for]="end_picker"></mat-datepicker-toggle>
            <mat-datepicker #end_picker></mat-datepicker>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline">
            <mat-label>{{'new_doc.office' | translate}}</mat-label>
            <!-- <mat-select formControlName="from_to" placeholder="{{'report.organization' | translate}}" [required]="true"
              [attr.disabled]="newCondition.doc_type == 'ផ្ទៃក្នុង'" (selectionChange)="refreshListReports()">
              <mat-option value="null">{{'report.select.all' | translate}}</mat-option>
              <mat-option *ngFor="let org of organizationList" [value]="org._id">{{org.name}}</mat-option>
            </mat-select> -->

            <mat-select formControlName="office" [placeholder]="'new_doc.office' | translate">
              <mat-option value="">{{'office.create_edit_dialog.form.none' | translate}}</mat-option>

              <ng-template #officeList let-list>
                <ng-container *ngFor="let office of list.data">
                  <div *ngIf="list.collapse || isEditing" [style.padding-left]=" 18 * list.index + 12 + 'px'" class="tfield color-primary flex">
                    <button style="float: left; height: 45px; width: 45px;" (click)="office.collapse = !!!office.collapse; clickCollapse(office)" [style.visibility]="office.childs.length ? 'show': 'hidden' " mat-icon-button>
                      <mat-icon *ngIf="!office.collapse">arrow_right</mat-icon>
                      <mat-icon *ngIf="office.collapse">arrow_drop_down</mat-icon>
                    </button>
                    <mat-option style="width: calc(100% - 45px); float: right;" [value]="office">
                      {{office.name}}
                    </mat-option>
                  </div>

                  <ng-container *ngIf="office.childs">
                    <ng-container *ngTemplateOutlet="officeList; context:{ $implicit: { data: office.childs, index: list.index + 1, parent: office, collapse: office.collapse }}">
                    </ng-container>
                  </ng-container>

                </ng-container>
              </ng-template>

              <ng-container *ngTemplateOutlet="officeList; context:{ $implicit: {data: offices, index: 0, parent: null, collapse: true}}">
              </ng-container>

            </mat-select>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field color="accent" appearance="outline">
            <mat-label>{{ "new_doc.type" | translate }}</mat-label>
            <mat-select formControlName="type" [placeholder]="'new_doc.doc_type.placeholder' | translate" [required]="true">
              <ng-template #typeList let-list>
                <ng-container *ngFor="let type of list.data; let i = index">
                  <div [style.padding-left]="18 * list.index + 12 + 'px'" class="tfield color-primary">
                    <button style="float: left; height: 45px; width: 45px;" (click)="type.collapse = !!!type.collapse; clickCollapse(type)"
                          [style.visibility]="type.childs.length ? 'show' : 'hidden'" mat-icon-button>
                      <mat-icon *ngIf="!type.collapse">arrow_right</mat-icon>
                      <mat-icon *ngIf="type.collapse">arrow_drop_down</mat-icon>
                    </button>
                    <mat-option [disabled]="type.parent == null" style="width: calc(100% - 45px); float: right" [value]="type">
                      {{ type.type }}
                    </mat-option>
                  </div>

                  <ng-container *ngIf="type.childs">
                    <ng-container *ngTemplateOutlet="typeList; context: { $implicit: { data: type.childs, index: list.index + 1, parent: type, collapse: true}}">
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-template>

              <ng-container *ngTemplateOutlet="typeList; context: {$implicit: {data: types,index: 0,parent: null,collapse: true}}">
              </ng-container>
            </mat-select>
          </mat-form-field>
        </p>
      </form>
    </div>

    <div id="print_report">
      <table mat-table [dataSource]="export_reports" #table>
        <ng-container matColumnDef="no">
          <th mat-header-cell *matHeaderCellDef class="center">{{'report.report_doc.num' | translate}}</th>
          <td @fade mat-cell *matCellDef="let i = index" class="center">
            {{ i + currentPage * limit - limit + 1 | localeNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>{{'report.report_doc.title' | translate}}</th>
          <td @fade mat-cell *matCellDef="let export_report;">{{export_report.title}}</td>
        </ng-container>
        <!-- { REF } -->
        <ng-container matColumnDef="ref">
          <th mat-header-cell *matHeaderCellDef>{{'new_doc.reference_id' | translate}}</th>
          <td @fade mat-cell *matCellDef="let export_report;">{{ export_report.reference_id || ('global.empty_code' | translate) }}</td>
        </ng-container>
        <!-- { DATE } -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>{{'all_documents.detail_dialog.date' | translate}}</th>
          <td @fade mat-cell *matCellDef="let export_report;">{{export_report.date | localeDate}}</td>
        </ng-container>
        <!-- { STATE } -->
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>{{'new_doc.state' | translate}}</th>
          <td @fade mat-cell *matCellDef="let export_report;">
            <!-- {{ export_report.states.join(',') }} -->
            <ng-container *ngFor="let state of export_report.states; let i = index">
              {{ lang == 'en' ? state.name : state.name_kh }} {{ i + 1 == export_report.states.length ? null : ',' }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="office">
          <th mat-header-cell *matHeaderCellDef class="center">{{'report.report_doc.office' | translate}}</th>
          <td @fade mat-cell *matCellDef="let export_report;" class="center">{{export_report.office.name}}</td>
        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef class="center">{{'report.report_doc.user' | translate}}</th>
          <td @fade mat-cell *matCellDef="let export_report;" class="center">{{export_report?.created_user?.last_name}}
            {{export_report?.created_user?.first_name}}</td>
        </ng-container>

        <ng-container matColumnDef="created_date">
          <th mat-header-cell *matHeaderCellDef class="center">{{'report.report_doc.createdAt' | translate}}</th>
          <td @fade mat-cell *matCellDef="let export_report;" class="center">
            {{ export_report.createdAt | localeDate }}</td>
        </ng-container>

        <!-- { ORDER } -->
        <ng-container matColumnDef="order">
          <th mat-header-cell *matHeaderCellDef>{{'global.other' | translate}}</th>
          <td @fade mat-cell *matCellDef="let export_report;">{{ export_report.other || ('global.empty_code' | translate) }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let data; columns: displayedColumns"></tr>
      </table>
      <mat-paginator #paginator *ngIf="!isEmpty" showFirstLastButtons [length]="total" [pageSize]="limit" (page)="onPageChange($event)" [pageSizeOptions]="itemPerPage">
      </mat-paginator>
      <div *ngIf="isEmpty" class="empty-box" id="empty_show">
        <img src="/assets/images/empty1.svg" alt="">
        <h3>{{'empty_list' | translate}}</h3>
      </div>
    </div>
  </div>
</div>
