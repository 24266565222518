export enum LocalStorageEnum {
    language = "locale",
    token = "access_token",
    refresh_token = "refresh_token",
    user_role_id = "user_role_id",
    user_id = "user_id",
    offic_id = "office_id",
    user = "user",
    tokenExpiryTime = "TET",
    dashboardStartDate = "dashboard::startdate",
    dashboardEndDate = "dashboard::enddate",
    selected_item = "selected_item",
    filter = "filter",
    chat_room = "chat_room",
    exptime = "exptime",
    gdntToken = "gdntToken"
}   