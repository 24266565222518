import { state } from '@angular/animations';
import { Location } from "@angular/common";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import {
  Component,
  OnDestroy,
  OnInit,
  ɵɵsetComponentScope,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { LocalStorageEnum } from "src/app/core/models/enum/local-storage.enum";
import { MODELS } from "src/app/core/models/enum/model.enum";
import { REQUEST_TYPE } from "src/app/core/models/enum/request-type.enum";
import { STATUSES } from "src/app/core/models/enum/request_status.enum";
import { ROLE } from "src/app/core/models/enum/role.enum";
import { SERVER_STATUS } from "src/app/core/models/enum/server_response.enum";
import { TRACKING_REQUEST_TYPE } from "src/app/core/models/enum/tracking-request-type.enum";
import { ProgressModel } from "src/app/core/models/progress.model";
import { ITrackingRequest } from "src/app/core/models/tracking-request.model";
import { TrackingModel } from "src/app/core/models/tracking.model";
import { AuthService } from "src/app/core/services/auth.service";
import { LocalstorageService } from "src/app/core/services/localstorage.service";
import { NavService } from "src/app/core/services/nav.service";
import { NewAPIService } from "src/app/core/services/new-api.service";
import { AlertConfirmDialogComponent } from "src/app/shared/dialogs/alert-confirm-dialog/alert-confirm-dialog.component";
import { ChangePasswordDialogComponent } from "src/app/shared/dialogs/change-password-dialog/change-password-dialog.component";
import { ConfirmDialogComponent } from "src/app/shared/dialogs/confirm-dialog/confirm-dialog.component";
import { CreateUserDialogComponent } from "src/app/shared/dialogs/create-user-dialog/create-user-dialog.component";
import { SubmitReasonDialogComponent } from "src/app/shared/dialogs/submit-reason/submit-reason-dialogcomponent";
import { ViewFileDialogComponent } from "src/app/shared/dialogs/view-file-dialog/view-file-dialog.component";

@Component({
  selector: "app-detail-request",
  templateUrl: "./detail-request.component.html",
  styleUrls: ["./detail-request.component.scss"],
})
export class DetailRequestComponent implements OnInit, OnDestroy {
  reference: any;
  request: any = {};
  referenceAvatar: any = "";
  user_role_id: ROLE;
  user_id: string;
  ROLES = ROLE;
  isLoading: boolean = true;
  isAvatar: boolean = false;
  failedRequest: boolean = false;
  requestTypes = REQUEST_TYPE;
  trackingRequestType = TRACKING_REQUEST_TYPE;
  isUser: boolean = false;
  lang: string = this.ls.get(LocalStorageEnum.language);
  offices: Array<any> = [];
  documentBoxes: any[] = new Array<string>();
  documentFolders: any[] = new Array<string>();
  requestorAvatar: any = "";
  submitLoading: boolean = false;
  selectUserDisplayCol: string[] = ["no", "code", "username", "office"];
  trackingList: ITrackingRequest[] = [];
  trackingLoading: boolean = true;

  allowVerify: boolean = false;
  allowApprove: boolean = false;

  isDeleteRequest: boolean;
  isUpdateRequest: boolean;
  isVerifyRequest: boolean;
  isResetPassword: boolean;
  isPending: boolean;
  isVerified: boolean;
  isApproved: boolean;
  isYourReq: boolean;
  isRejected: boolean;
  isCreate: boolean;

  readonly ALLOW_VIEW_REQUESTS_ROLE: ROLE[] = [
    ROLE.ADMIN,
    ROLE.APPROVER,
    ROLE.VERIFIER,
    ROLE.WRITER,
    ROLE.POST_OFFICER,
  ];
  private _destroyed = new Subject<void>();
  isStateCopy : boolean
  stateCopyID = '606ad62feb78622edab42164'
  constructor(
    private routes: ActivatedRoute,
    private router: Router,
    private newApi: NewAPIService,
    private ls: LocalstorageService,
    private location: Location,
    private dialog: MatDialog,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private nav: NavService,
    private auth: AuthService,
    private title: Title
  ) {
    let user = JSON.parse(this.ls._getDecryption(LocalStorageEnum.user));
    this.user_role_id = user.role._id.toString();
    this.user_id = user._id;

    translate.onDefaultLangChange
      .pipe(takeUntil(this._destroyed))
      .subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });
  }

  ngOnInit(): void {
    this.routes.params
      .pipe(takeUntil(this._destroyed))
      .subscribe((params: any) => {
        this.newApi
          .newGetRequestById(params.id)
          .pipe(takeUntil(this._destroyed))
          .subscribe((response: any) => {
            if (response.status < 1) {
              this.failedRequest = true;
              this.goBack();
              return "Invalid Request";
            }
            this.isLoading = false;
            this.request = response.request;
            this.isDeleteRequest =
              this.request.type.name == this.requestTypes.DELETE;
            this.isCreate = this.request.type.name == this.requestTypes.CREATE;
            this.isUpdateRequest =
              this.request.type.name == this.requestTypes.UPDATE;
            this.isVerifyRequest =
              this.request.type.name == this.requestTypes.VERYFY;
            this.isResetPassword =
              this.request.type.name == this.requestTypes.RESET_PASSWORD;
            this.isPending =
              this.request.status.status_name == STATUSES.PENDING;
            this.isVerified =
              this.request.status.status_name == STATUSES.VERIFIED;
            this.isApproved =
              this.request.status.status_name == STATUSES.APPROVED;
            this.isRejected =
              this.request.status.status_name == STATUSES.REJECTED;
            this.isYourReq = this.request.requestor._id == this.user_id;
            this.allowApprove =
              (this.isPending || this.isVerified) &&
              (this.user_role_id == this.ROLES.ADMIN ||
                this.user_role_id == this.ROLES.APPROVER ||
                ((this.isDeleteRequest ||
                  this.isUpdateRequest ||
                  this.isResetPassword) &&
                  this.user_role_id == ROLE.VERIFIER));

            this.allowVerify =
              this.isPending &&
              (this.user_role_id == ROLE.ADMIN ||
                this.user_role_id == ROLE.APPROVER ||
                this.user_role_id == ROLE.VERIFIER);
            // console.log(this.allowApprove, this.allowVerify)
            this.reference = this.request.reference;
            this.reference.folders = this.reference.folders || [];
            this.isStateCopy = this.reference.states.some(item => item._id === this.stateCopyID);
            this.reference.folders.forEach((folder) => {
              this.documentBoxes.push(folder.parent.code);
            });
            this.documentFolders = this.reference.folders.map(
              (folder) => folder.code
            );
            this.isUser =
              this.request.tmp_model == MODELS.USER ||
              this.request.onModel == MODELS.USER;
            if (this.request.requestor.avatar)
              this.newApi
                .getAvatars(this.request.requestor.avatar)
                .pipe(takeUntil(this._destroyed))
                .subscribe((avatar) => {
                  this.requestorAvatar = this.sanitizer.bypassSecurityTrustUrl(
                    URL.createObjectURL(avatar as any)
                  );
                  this.isAvatar = true;
                  URL.revokeObjectURL(this.requestorAvatar);
                });
            if (this.isUser) {
              this.title.setTitle(
                "User Request - " +
                  this.reference.first_name +
                  " " +
                  this.reference.last_name
              );
              this.offices = this.reference.manage_office_tree;
              if (this.reference.avatar) {
                this.newApi
                  .getAvatars(this.reference.avatar)
                  .pipe(takeUntil(this._destroyed))
                  .subscribe((avatarBlob) => {
                    this.referenceAvatar =
                      this.sanitizer.bypassSecurityTrustUrl(
                        URL.createObjectURL(avatarBlob as any)
                      );
                    URL.revokeObjectURL(this.referenceAvatar);
                  });
              }
            } else {
              this.title.setTitle("Document Request - " + this.reference.title);
            }
          });

        if (this.ALLOW_VIEW_REQUESTS_ROLE.includes(this.user_role_id)) {
          this.newApi
            .getTrackingRequest(1, 10, params.id)
            .pipe(takeUntil(this._destroyed))
            .subscribe((res) => {
              this.trackingLoading = false;
              if (res.status == SERVER_STATUS.SUCCESS) {
                let visible_reg_type = [
                  "Reject_Request",
                  "Approve_Request",
                  "Verify_Request",
                  "Update_Request",
                ];
                let tracking_data: ITrackingRequest[] = res.audit_logs || [];
                this.trackingList = tracking_data.filter(
                  (item) =>
                    visible_reg_type.includes(item.type) &&
                    item?.reference?.type?.name == this.request?.type?.name
                );
              }
            });
        }
      });
  }

  isOfficeCheck(officeID: string) {
    return this.reference.manage_office_ids.some((ele) => ele === officeID);
  }

  goBack() {
    return this.location.back();
  }

  private redirect(is_determined: boolean = false) {
    if (this.isUser) this.router.navigateByUrl("/requests/user");
    else
      this.router.navigateByUrl(
        `/requests/document/${is_determined ? "2" : "1"}`
      );
  }

  onVerify() {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: "450px",
        disableClose: true,
        data: this.isUpdateRequest
          ? {
              isNegative: false,
              isDelete: this.isDeleteRequest,
              request: this.request,
              title: this.translate.instant("approve.title"),
              subtitle: this.translate.instant("approve.question"),
              positiveText: this.translate.instant("approve.postive"),
              negativeText: this.translate.instant("cancel"),
            }
          : this.isDeleteRequest
          ? {
              request: this.request,
              isDelete: this.isDeleteRequest,
              isNegative: false,
              title: this.translate.instant("delete.title"),
              subtitle: this.translate.instant("delete.question"),
              positiveText: this.translate.instant("delete.positive"),
              negativeText: this.translate.instant("delete.negative"),
            }
          : {
              request: this.request,
              isDelete: this.isDeleteRequest,
              isNegative: false,
              title: this.translate.instant("verify.title"),
              subtitle: this.translate.instant("verify.question"),
              positiveText: this.translate.instant("verify.postive"),
              negativeText: this.translate.instant("verify.negative"),
            },
      })
      .afterClosed()
      .subscribe((response) => {
        if (!response) return;
        this.submitLoading = true;
        if (
          this.request.type.name === this.requestTypes.UPDATE ||
          this.request.type.name == this.requestTypes.DELETE
        ) {
          this.newApi.approveRequest(this.request._id).subscribe((response) => {
            if (response.status == SERVER_STATUS.SUCCESS) {
              this.nav.updatePendingCount();
              this.redirect(true);
            }
          });
        } else {
          this.newApi.verifyRequest(this.request._id).subscribe((res: any) => {
            if (res.status == SERVER_STATUS.SUCCESS) {
              this.nav.updatePendingCount();
              this.redirect(this.user_role_id == ROLE.VERIFIER);
            }
          });
        }
      });
  }

  openEditUserDialog(): void {
    this.dialog
      .open(CreateUserDialogComponent, {
        data: {
          user: this.reference,
          isRequest: true,
          requestID: this.request.original_id,
          isCreateReq: this.isCreate,
        },
      })
      .afterClosed()
      .subscribe((response) => {});
  }

  onApprove(): void {
    if (this.isResetPassword) this.onApproveResetPassword();
    else this.onApproveRequest();
  }

  onApproveRequest() {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: "450px",
        disableClose: true,
        data: {
          isNegative: false,
          request: this.request,
          title: this.translate.instant("approve.title"),
          subtitle: this.translate.instant("approve.question"),
          positiveText: this.translate.instant("approve.postive"),
          negativeText: this.translate.instant("cancel"),
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (!response) return;
        this.submitLoading = true;
        this.newApi.approveRequest(this.request._id).subscribe((response) => {
          if (response.status == SERVER_STATUS.SUCCESS) {
            this.nav.updatePendingCount();
            this.redirect(false);
          }
        });
      });
  }

  onApproveResetPassword() {
    this.dialog
      .open(ChangePasswordDialogComponent, {
        panelClass: "less-shadow",
        disableClose: true,
        width: "400px",
        data: {
          is_approval: true,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (!response) return;

        this.submitLoading = true;
        const dataJson = {
          reset_password: response.reset_password,
          confirm_reset_password: response.confirm_reset_password,
        };

        this.newApi
          .approveRequest(this.request._id, dataJson)
          .subscribe((response) => {
            if (response.status == SERVER_STATUS.SUCCESS) {
              this.nav.updatePendingCount();
              this.redirect(false);
            }
          });
      });
  }

  removeDuplicatedArray(array: any[]) {
    return Array.from(new Set(array));
  }

  openReasonDialog() {
    this.dialog
      .open<SubmitReasonDialogComponent>(SubmitReasonDialogComponent, {
        panelClass: "less-shadow",
        width: "450px",
        disableClose: true,
        data: {
          isNegative: true,
          request: this.request,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (!response) return;
        this.nav.updatePendingCount();
        this.redirect(true);
      });
  }

  showOldDocumentInfo() {
    let url = window.location.href;
    let arr = url.split("/");
    let baseUrl = [arr[0], arr[1], arr[2]].join("/");
    window.open(
      `${baseUrl}/documents/detail/${this.request.original_id}`,
      "_blank"
    );
  }

  showOldUserInfo() {
    let url = window.location.href;
    let arr = url.split("/");
    let baseUrl = [arr[0], arr[1], arr[2]].join("/");
    window.open(
      `${baseUrl}/users/management/detail/${this.request.original_id}`,
      "_blank"
    );
  }

  get isAllowOldInfoButton() {
    let valid_role: boolean = [
      ROLE.ADMIN,
      ROLE.APPROVER,
      ROLE.VERIFIER,
    ].includes(this.user_role_id as ROLE);
    let valid_type: boolean = [REQUEST_TYPE.VERYFY].includes(
      this.request.type.name
    );
    let valid_status: boolean = [STATUSES.PENDING, STATUSES.VERIFIED].includes(
      this.request.status.status_name
    );
    return valid_role && valid_type && valid_status;
  }

  private updateDownloadProgress(
    event: HttpEvent<Response>,
    progress: ProgressModel
  ): void {
    switch (event.type) {
      case HttpEventType.Sent:
        // this.snackbar.open(this.translate.instant("global.start_downloading") + " " + progress.name, null, {
        //   duration: 1000,
        //   panelClass: "panelClass-success"
        // });
        break;
      case HttpEventType.DownloadProgress:
        progress.downloadProgress = Math.floor(
          (100 * event.loaded) / event.total
        );
        break;
      case HttpEventType.Response:
      // this.snackbar.open(this.translate.instant("global.download_completed") + " " + progress.name, null, {
      //   duration: 1000,
      //   panelClass: "panelClass-success"
      // });
      default:
        return;
    }
  }

  downloadFile(progress) {
    this.dialog
      .open(AlertConfirmDialogComponent, {
        width: "480px",
        data: {
          title: "dialog.confirm.download_file.title",
          descriptions: [
            "dialog.confirm.download_file.description",
            "dialog.confirm.download_file.question",
          ],
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (!response) return;
        progress.isDownloading = true;
        this.newApi
          .downloadFileWithoutAuth(progress._id, { is_download: true })
          .pipe(
            map((event) => {
              this.updateDownloadProgress(event, progress);
              return event;
            })
          )
          .subscribe((response: any) => {
            if (response.body && response.body.constructor == Blob) {
              progress.isDownloading = false;
              let downloadUrl = URL.createObjectURL(response.body);
              let a = document.createElement("a");
              a.href = downloadUrl;
              a.download = progress.name;
              a.click();
            }
          });
      });
  }

  translateDate(dateTime: string, spacer: string = "-"): string {
    const date = dateTime.split(" ")[0].split("-");
    const time = dateTime.split(" ")[1]
      ? dateTime.split(" ")[1].split(":")
      : "";
    dateTime = `${date[0]}${spacer}${this.translate.instant(
      "months." + date[1]
    )}${spacer}${date[2]} ${Array.isArray(time) ? time.join(":") : time} ${
      dateTime.split(" ")[2] || ""
    }`;
    return dateTime;
  }

  isNumber(type) {
    return !isNaN(type);
  }

  get requestTypeLocalize(): string {
    return this.request.type.name.replace(/ /g, "_");
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this._destroyed.next();
    this._destroyed.complete();
  }

  viewFile(file) {
    this.dialog.open<ViewFileDialogComponent>(ViewFileDialogComponent, {
      height: "90vh",
      disableClose: true,
      autoFocus: false,
      backdropClass: "file-viewer",
      panelClass: "custom-file-container",
      data: {
        file,
        is_download: false,
      },
    });
  }

  goToEdit(id: string) {
    this.router.navigateByUrl(`/documents/edit/${id}`);
  }

  /**
   * allow verifier or approver to update the request before
   * verified or approved.
   */
  get isAllowUpdate() {
    let validReqType = this.isCreate || this.isVerifyRequest;
    let validVerifier = this.user_role_id == ROLE.VERIFIER && this.isPending;
    let validApprover =
      this.user_role_id == ROLE.APPROVER && (this.isPending || this.isVerified);
    let admin =
      this.user_role_id == ROLE.ADMIN && (this.isPending || this.isVerified);
    return (
      (validReqType && (validVerifier || validApprover || admin)) ||
      (this.isYourReq && this.isUpdateRequest && this.isApproved)
    );
  }
}
